<div class="card card-gray-dark color-pallete-box">
  <div class="card-header">
    <h3 class="card-title">
      <i class="fas fa-home"></i> Casa Comunitária
    </h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <label>Nome:</label>
          <br>
          <span class="form-control">{{ casaComunitaria?.nome != undefined ?
            casaComunitaria?.nome : '' }}</span>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <label>Data de Fundação:</label>
          <br>
          <span class="form-control">{{ casaComunitaria?.dataFundacao | date :
            'dd/MM/yyyy' }}</span>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <label>Missao:</label>
          <br>
          <span class="form-control">{{ casaComunitaria?.missaoNome !=
            undefined ?
            casaComunitaria?.missaoNome : '' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>