import { Component, Input, OnInit } from "@angular/core";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { MissionarioMissaoAtualService } from "src/app/secured/wop/dadosvocacionais/service/missionariomissaoatual.service";
import { ImagemService } from "src/app/secured/wop/imagem/service/imagem.service";
import { PessoaService } from "src/app/secured/wop/pessoa/service/pessoa.service";

@Component({
	selector: "listagem-membros-casa-comunitaria-comp",
	templateUrl: "./listagem-membros-casa-comunitaria.component.html",
	styleUrls: ["./listagem-membros-casa-comunitaria.component.css"],
})
export class ListagemMembrosCasaComunitariaComponent implements OnInit {
	@Input()
	private casaComunitariaId: number;
	public busca: any;
	public totalDePaginas: number;
	public membrosCasaComunitaria: Array<any>;

	constructor(
		public readonly missionarioMissaoAtualService: MissionarioMissaoAtualService,
		public readonly pessoaService: PessoaService,
		public readonly imagemService: ImagemService,
		public readonly swtAlert2Service: SwtAlert2Service
	) {
		this.membrosCasaComunitaria = new Array<any>();
		this.busca = {
			casaComunitariaId: null,
			formaVidaId: null,
			pageNumber: 0,
			quantityOfElements: 5,
		};
	}

	ngOnInit() {
		(async () => {
			await this.carregarMembrosCasaComunitaria();
		})();
	}

	cliqueNoBotaoListener(botao) {
		this.busca.pageNumber = botao.numero - 1;
		this.carregarMembrosCasaComunitaria();
	}

	async carregarMembrosCasaComunitaria(): Promise<any> {
		try {
			this.busca.casaComunitariaId = this.casaComunitariaId;
			// this.busca.formaVidaId = 17; // Comunidade de Vida
			let response =
				await this.pessoaService.buscarMembrosCasaComunitaria(
					this.busca
				);
			this.membrosCasaComunitaria = response.entity;

			this.totalDePaginas = response.quantity;

			for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
				if (this.membrosCasaComunitaria[i].imagemId != null) {
					let blogImage = await this.getPessoaImagem(
						this.membrosCasaComunitaria[i].imagemId
					);

					this.imagemService.createImage(
						blogImage,
						this.membrosCasaComunitaria[i]
					);
				}
			}
		} catch (err) {
			this.swtAlert2Service.errorAlert(err.error.errors);
		}
	}

	async getPessoaImagem(id: number): Promise<any> {
		try {
			let response = await this.imagemService.getPessoaPhoto(id);
			return response.body;
		} catch (err) {
			this.swtAlert2Service.errorAlert(err.error.errors);
		}
	}
}
