import { GenericService } from "src/app/core/services/generic.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class AtualizarAcompanhamentoCasaComunitariaService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType("acompanhamentocasacomunitaria");
    }
    executar(id, dados) {
        return this.http.put(`${this.apiURL}/${id}`, JSON.stringify(dados), {
            headers: this.getHeaders(),
        });
    }
}
AtualizarAcompanhamentoCasaComunitariaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AtualizarAcompanhamentoCasaComunitariaService_Factory() { return new AtualizarAcompanhamentoCasaComunitariaService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: AtualizarAcompanhamentoCasaComunitariaService, providedIn: "root" });
