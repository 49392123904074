import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'select2';
import { environment } from "src/environments/environment";
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { WopGetAllService } from 'src/app/service/getall.service';
import { Missionario } from 'src/app/model/missionario.model';
import { MissionarioService } from './service/missionario.service';
import { ImagemService } from "../../wop/imagem/service/imagem.service";

import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';

import { Apostolado } from 'src/app/model/apostolado.model';
import { ApostoladoBuilder } from 'src/app/builder/apostolado.builder';
import { MissionarioBuilder } from 'src/app/builder/missionario.builder';
import { ApostoladoService } from '../../wop/dadosvocacionais/service/apostolado.service';
import { Difusao } from 'src/app/model/difusao.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';

@Component({
  selector: 'missionarios.buscaavancada',
  templateUrl: './missionarios.buscaavancada.component.html',
  styleUrls: ['../../wop/pessoa/style/style.css', '../../wop/pessoa/style/normalize.css']  
})
export class MissionariosBuscaAvancadaComponent implements OnInit {

  public missionarios: Array<Missionario>;
  public missoes: Array<Missao>;
  public difusoes: Array<Difusao>;
  public casasComunitarias: Array<CasaComunitaria>;
  public formasVida: Array<ElementoGrupoDominio>;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public estadoVida: Array<ElementoGrupoDominio>;
  public habilidades: Array<ElementoGrupoDominio>;
  public linguas: Array<ElementoGrupoDominio>;
  public niveisEscolaridade: Array<ElementoGrupoDominio>;
  public status: Array<ElementoGrupoDominio>;
  public paises: Array<Pais>;
  public busca: any;
  public totalDePaginas: number; 
  public urlBaseImagem: string;
  public apostolados: Array<Apostolado>;

  public pessoasFazendoTerapia : number;
  public pessoasCV : number;
  public pessoasCA : number;
  public totalMissionarios : number;
  public tipoFiltro: string;

  constructor(
    protected router: Router,        
    public route: ActivatedRoute,
    public wopGetAllService: WopGetAllService,
    public swtAlert2Service: SwtAlert2Service,
    public missionarioService: MissionarioService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public apostoladoService: ApostoladoService,
    public missionarioBuilder: MissionarioBuilder,
    public casaComunitariaService: CasaComunitariaService
  ) { 
    this.paises = new Array<Pais>();
    this.missoes = new Array<Missao>();
    this.missionarios = new Array<Missionario>();
    this.habilidades = new Array<ElementoGrupoDominio>();       
    this.linguas = new Array<ElementoGrupoDominio>();
    this.niveisEscolaridade = new Array<ElementoGrupoDominio>(); 
    this.status = new Array<ElementoGrupoDominio>();       
    this.casasComunitarias = new Array<CasaComunitaria>();
    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.busca = {
        nome: null,
        sobrenome: null,
        genero: null,
        faixaEtaria: null,
        nacionalidadeId: null,
        missaoAtualId: null,
        difusaoAtualId: null,
        formaVidaId: null,
        estadoVidaId: null,
        habilidadesIds: null,
        idiomasIds: null,
        nivelEscolaridadeId: null,
        status: 124,
        apostoladosAtuaisIds: null,
        apostoladosJaExercidosIds: null,
        pessoasIds: null,
        casaComunitariaId: null,
        pageNumber: 0,
        quantityOfElements : 10
    };
    this.pessoasFazendoTerapia = 0;
    this.pessoasCV = 0;
    this.pessoasCA = 0;
    this.totalMissionarios = 0;
  }

  ngOnInit() {
    this.tipoFiltro = String(this.route.snapshot.paramMap.get('tipo')); 
    this.carregarFormasVida();
    this.carregarNiveisFormacao();
    this.carregarEstadoVida();
    this.carregarPaises(); 
    this.carregarMissoes();
    this.carregarDifusoes();
    this.carregarLinguas();
    this.carregarHabilidades();
    this.carregarNiveisEscolaridade();
    this.carregarStatus();
    this.carregarApostolados();
    this.buscarMissionarios();   
    
    
    $("select[name='linguas-select']").select2({
        theme: "bootstrap4"            
    });

    $("select[name='habilidades-select']").select2({
        theme: "bootstrap4"
    });

    $("select[name='apostoladoatual-select']").select2({
        theme: "bootstrap4"
    });    

    $("select[name='apostoladojaexercido-select']").select2({
        theme: "bootstrap4"
    });      

  }

  async carregarFormasVida() : Promise<any> {

    let request = {
        grupoNome: 'FORMA_VIDA'
    }

    try
    {
        let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
        this.formasVida = response.entity;
    }
    catch(e)
    {
        this.swtAlert2Service.errorAlert(e.error.errors);
    }

  }

  async carregarNiveisFormacao() : Promise<any> {

      let request = {
          grupoNome: 'NIVEL_FORMACAO'
      }

      try
      {
          let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
          this.niveisFormacao = response.entity;
      }
      catch(e)
      {
          this.swtAlert2Service.errorAlert(e.error.errors);
      }

  }

  async carregarEstadoVida() : Promise<any> {

    let request = {
        grupoNome: 'ESTADO_VIDA'
    }

    try
    {
        let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
        this.estadoVida = response.entity;
    }
    catch(e)
    {
        this.swtAlert2Service.errorAlert(e.error.errors);
    }

  }

  async carregarPaises() : Promise<any> {

      try
      {
          let response = await this.wopGetAllService.getAllPais();
          this.paises = response.entity.sort((a:Pais, b:Pais) => a.nome.localeCompare(b.nome))
      }
      catch(e)
      {
          this.swtAlert2Service.errorAlert(e.error.errors);
      }

  }

  async carregarMissoes(): Promise<any> {

      try 
      {
          let response = await this.wopGetAllService.getAllMissao();   
          this.missoes = response.entity;     
          this.missoes = this.missoes.sort( (a:Missao, b:Missao) => a.nome.localeCompare(b.nome));
      }
      catch(e)
      {
          this.swtAlert2Service.errorAlert(e.error.errors);
      }
  }

  async carregarDifusoes(): Promise<any> {

    try 
    {
        let response = await this.wopGetAllService.getAllDifusao();   
        this.difusoes = response.entity;     
        this.difusoes = this.difusoes.sort( (a:Difusao, b:Difusao) => a.nome.localeCompare(b.nome));
    }
    catch(e)
    {
        this.swtAlert2Service.errorAlert(e.error.errors);
    }
} 

async carregarCasasComunitarias(): Promise<any> {

    try 
    {
        let response = await this.casaComunitariaService.porMissao(this.busca.missaoAtualId);   
        this.casasComunitarias = response.entity;     
        this.casasComunitarias = this.casasComunitarias.sort( (a:CasaComunitaria, b:CasaComunitaria) => a.nome.localeCompare(b.nome));
    }
    catch(e)
    {
        this.swtAlert2Service.errorAlert(e.error.errors);
    }
} 

  async carregarHabilidades() : Promise<null> 
  {
      let request = {
          grupoNome : 'HABILIDADE'
      }

      try
      {            
          let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
          this.habilidades = response.entity;
      }
      catch(e)
      {
          console.log(e);
      };

      return null;
  }

  async carregarLinguas() : Promise<null> 
  {
      let request = {
          grupoNome : 'LINGUA'
      }

      try
      {            
          let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
          this.linguas = response.entity;
      }
      catch(e)
      {
          console.log(e);
      };

      return null;
  }

  async carregarNiveisEscolaridade() : Promise<null> 
  {
      let request = {
          grupoNome : 'NIVEL_ESCOLARIDADE'
      }

      try
      {            
          let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
          this.niveisEscolaridade = response.entity;
      }
      catch(e)
      {
          console.log(e);
      };

      return null;
  }

  async carregarStatus() : Promise<null> 
  {
      let request = {
          grupoNome : 'PESSOA_STATUS'
      }

      try
      {            
          let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
          this.status = response.entity;          
      }
      catch(e)
      {
          console.log(e);
      };

      return null;
  }

  async buscarMissionarios() : Promise<any> {

    try
    {

        this.busca.idiomasIds = $("select[name='linguas-select']").val();
        this.busca.habilidadesIds = $("select[name='habilidades-select']").val();
        this.busca.apostoladosAtuaisIds = $("select[name='apostoladoatual-select']").val();
        this.busca.apostoladosJaExercidosIds = $("select[name='apostoladojaexercido-select']").val();

        let response = await this.missionarioService.buscarMissionarios(this.busca);

        this.pessoasFazendoTerapia = response.fazTerapia;
        this.pessoasCV = response.comunidadeVida;
        this.pessoasCA = response.comunidadeAlianca;
        this.totalMissionarios = response.totalMissionarios;

        this.missionarios = response.entity;

        this.totalDePaginas = response.quantity;

        for(let i = 0; i < this.missionarios.length ; i++)
        {

            if(this.missionarios[i].imagemId != null)
            {
                let blogImage = await this.getMissionarioImagem(this.missionarios[i].imagemId);

                this.imagemService.createImage(blogImage, this.missionarios[i]);
            }  
            
        } 

    }
    catch(err)
    {
        this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getMissionarioImagem(id:number): Promise<any> {
      try
      {
          let response = await this.imagemService.getPessoaPhoto(id);
          return response.body;
      }
      catch(err)
      {
          this.swtAlert2Service.errorAlert(err.error.errors);
      }
  }

  async carregarApostolados() : Promise<any> 
  {
      let request = {
          ativo : null
      }
      try 
      {
          let response = await this.apostoladoService.pesquisar(request);
          this.apostolados = response.entity;
      } 
      catch (err) 
      {
          this.swtAlert2Service.errorAlert(err.error.errors)
      }
  }

  async setarRegistroDuplicado(pessoaId: number) : Promise<any> {

    try 
    {
        let response = await this.pessoaService.setarRegistroDuplicado(pessoaId);   
        this.swtAlert2Service.successAlert(response.message);
    } 
    catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
    }
 }

  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero-1;
    this.buscarMissionarios();
  }

}
