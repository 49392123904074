import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class VisualizarAcompanhamentoCasaComunitariaComponent {
    constructor(route, obterAcompanhamentoService) {
        this.route = route;
        this.obterAcompanhamentoService = obterAcompanhamentoService;
        this.$destroy = new Subject();
    }
    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.acompanhamentoCasaComunitariaId = Number(params.get("id"));
            this.carregarDadosAcompanhamentoCasaComunitaria();
        });
    }
    ngOnDestroy() {
        this.$destroy.next();
        this.$destroy.complete();
    }
    carregarDadosAcompanhamentoCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.obterAcompanhamentoService
                .executar(this.acompanhamentoCasaComunitariaId)
                .pipe(takeUntil(this.$destroy))
                .subscribe({
                next: (response) => (this.acompanhamentoCasaComunitaria = response),
            });
        });
    }
    converterPeriodoEmTexto(periodo) {
        switch (periodo) {
            case "MARCO_MAIO":
                return "De Março a Maio";
            case "JUNHO_AGOSTO":
                return "De Junho a Agosto";
            case "SETEMBRO_NOVEMBRO":
                return "De Setembro a Novembro";
            case "DEZEMBRO_FEVEREIRO":
                return "De Dezembro a Fevereiro";
            default:
                return "Não foi definido o período";
        }
    }
}
