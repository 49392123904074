import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MissionariosComponent } from './missionarios.component';
import { MissionarioViewComponent } from './missionario.view.component';
import { MissionariosBuscaAvancadaComponent } from './missionarios.buscaavancada.component';
import { MissionariosRoutingModule } from './missionarios-routing.module';
import { MissionarioService } from './service/missionario.service';
import { MissionarioBuilder } from 'src/app/builder/missionario.builder';
import { MissionarioDetalhadoViewComponent } from './missionario.detalhado.view.component';

@NgModule({
    declarations:[
        MissionarioViewComponent,
        MissionarioDetalhadoViewComponent,
        MissionariosComponent,
        MissionariosBuscaAvancadaComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        MissionariosRoutingModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule
    ],
     providers:[
         MissionarioService,
         MissionarioBuilder
     ]
 })
  export class MissionariosModule {
     
 }