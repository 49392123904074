<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3>Visualização - Casa Comunitária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <exibir-detalhes-casa-comunitaria-comp
                            [casaComunitariaId]="casaComunitariaId"></exibir-detalhes-casa-comunitaria-comp>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <visualizar-equipe-formacao-comp
                            [casaComunitariaId]="casaComunitariaId"></visualizar-equipe-formacao-comp>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <listagem-membros-casa-comunitaria-comp
                            [casaComunitariaId]="casaComunitariaId"></listagem-membros-casa-comunitaria-comp>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <listagem-acompanhamento-casa-comunitaria-comp
                            [casaComunitariaId]="casaComunitariaId"></listagem-acompanhamento-casa-comunitaria-comp>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>