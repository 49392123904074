import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { Page } from 'src/app/model/page.model';

export type MissaoConfiguracao = {
  id: number;
  missaoId: number;
  hasColegiado: boolean;
  hasComissaoDiscernimento: boolean;
};

@Injectable()
export class MissaoConfigService extends GenericService<MissaoConfiguracao> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('missao/configuracao');
  }

  public obterPorResponsavelLocal(): Observable<MissaoConfiguracao> {
    return this.http.get<MissaoConfiguracao>(
      `${this.apiURL}/porresponsavellocal`,
      {
        headers: this.getHeaders(),
      }
    );
  }

  public cadastrar(dados: MissaoConfiguracao): Observable<MissaoConfiguracao> {
    return this.http.post<MissaoConfiguracao>(
      `${this.apiURL}`,
      JSON.stringify(dados),
      {
        headers: this.getHeaders(),
      }
    );
  }

  public atualizar(dados: MissaoConfiguracao): Observable<MissaoConfiguracao> {
    return this.http.patch<MissaoConfiguracao>(
      `${this.apiURL}/${dados.missaoId}`,
      JSON.stringify(dados),
      {
        headers: this.getHeaders(),
      }
    );
  }
}
