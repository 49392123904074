<secured>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h3>Acompanhamento - Casa Comunitária</h3>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <exibir-detalhes-casa-comunitaria-comp *ngIf="acompanhamentoCasaComunitaria?.casaComunitariaId"
              [casaComunitariaId]="acompanhamentoCasaComunitaria?.casaComunitariaId"></exibir-detalhes-casa-comunitaria-comp>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card card-gray-dark color-pallete-box">
              <div class="card-header">
                <h3 class="card-title">
                  <i class="fas fa-file-alt"></i> Formulário de Acompanhamento
                </h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <label for="ano">Ano:</label>
                      <br>
                      <span class="form-control">{{acompanhamentoCasaComunitaria.ano}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <label for="periodo">Período do Acompanhamento:</label>
                      <br>
                      <span
                        class="form-control">{{converterPeriodoEmTexto(acompanhamentoCasaComunitaria.periodoAcompanhamento)}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <label for="data">Data do Acompanhamento:</label>
                      <br>
                      <span class="form-control">{{acompanhamentoCasaComunitaria.dataAcompanhamento | date :
                        'dd/MM/yyyy'}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="data">Acompanhador:</label>
                      <br>
                      <span class="form-control">{{acompanhamentoCasaComunitaria.acompanhadorPessoaNome}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="data">Formador:</label>
                      <br>
                      <span class="form-control">{{acompanhamentoCasaComunitaria.formadorPessoaNome}}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="partilha-formador">Partilha do formador:</label>
                      <textarea id="partilha-formador" class="form-control"
                        [(ngModel)]="acompanhamentoCasaComunitaria.partilhaFormador" name="partilha-formador" rows="4"
                        placeholder="Digite aqui..."></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="orientacoes-dadas">Orientações dadas:</label>
                      <textarea id="orientacoes-dadas" class="form-control"
                        [(ngModel)]="acompanhamentoCasaComunitaria.orientacoesDadas" name="orientacoes-dadas" rows="4"
                        placeholder="Digite aqui..."></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row justify-content-end">
                  <button class="btn btn-primary" (click)="atualizarAcompanhamentoCasaComunitaria()">Atualizar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</secured>