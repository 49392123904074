import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AcompanhamentoCasaComunitaria } from "src/app/model/acompanhamentocasacomunitaria.model";
import { ObterAcompanhamentosPorCasaComunitariaServiceService } from "./ObterAcompanhamentosPorCasaComunitariaService.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";

@Component({
	selector: "listagem-acompanhamento-casa-comunitaria-comp",
	templateUrl: "./listagem-acompanhamento-casa-comunitaria.component.html",
	styleUrls: ["./listagem-acompanhamento-casa-comunitaria.component.css"],
})
export class ListagemAcompanhamentoCasaComunitariaComponent
	implements OnInit, OnDestroy
{
	private $destroy = new Subject<void>();

	@Input()
	public casaComunitariaId: number;
	public busca: any;
	public totalDePaginas: number;
	listaAcompanhamento: Array<AcompanhamentoCasaComunitaria> =
		new Array<AcompanhamentoCasaComunitaria>();

	constructor(
		public readonly obterAcompanhamentosService: ObterAcompanhamentosPorCasaComunitariaServiceService,
		public readonly swtAlertService: SwtAlert2Service
	) {
		this.busca = {
			pageNumber: 0,
			quantityOfElements: 10,
		};
	}

	ngOnInit() {
		this.carregarAcompanhamentosPorCasaComunitaria();
	}

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	cliqueNoBotaoListener(botao) {
		this.busca.pageNumber = botao.numero - 1;
		this.carregarAcompanhamentosPorCasaComunitaria();
	}

	async carregarAcompanhamentosPorCasaComunitaria() {
		this.obterAcompanhamentosService
			.executar(this.casaComunitariaId)
			.pipe(takeUntil(this.$destroy))
			.subscribe({
				next: (response) => {
					this.totalDePaginas = response.totalPages;
					this.listaAcompanhamento = response.content;
				},
				error: (error) => this.swtAlertService.errorAlert(error.errors),
			});
	}

	converterPeriodoEmTexto(periodo: string) {
		switch (periodo) {
			case "MARCO_MAIO":
				return "De Março a Maio";
			case "JUNHO_AGOSTO":
				return "De Junho a Agosto";
			case "SETEMBRO_NOVEMBRO":
				return "De Setembro a Novembro";
			case "DEZEMBRO_FEVEREIRO":
				return "De Dezembro a Fevereiro";
			default:
				return "Não foi definido o período";
		}
	}
}
