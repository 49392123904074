<table-component [tituloSessao]="'Casas Comunitarias'" [cols]="4" [rows]="10"
  [totalDePaginas]="resultadoPesquisa?.totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)"
  *ngIf="resultadoPesquisa.casasComunitarias">
  <tr tableheader>
    <th style="width: 10px">#</th>
    <th>Nome</th>
    <th>Missão</th>
    <th></th>
  </tr>
  <ng-container tablebody *ngFor="let cc of resultadoPesquisa?.casasComunitarias">
    <row-listagem-casa-comunitaria-comp [casaComunitaria]="cc">
      <button class="btn btn-sm btn-default ml-1" routerLink="../adicaoacompanhamento/{{cc.id}}"
        title="Adicionar Acompanhamento" alt="Adicionar Acompanhamento"><i class="fas fa-plus"></i></button>
      <button class="btn btn-sm btn-secondary ml-1" routerLink="../visualizardetalhescompletos/{{cc.id}}"
        title="Visualizar Detalhes da Casa" alt="Visualizar Detalhes da Casa"><i class="fas fa-eye"></i></button>
    </row-listagem-casa-comunitaria-comp>
  </ng-container>
</table-component>