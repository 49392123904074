import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResultadoPesquisaCasaComunitaria } from '../pesquisa-casa-comunitaria/pesquisa-casa-comunitaria.component';

export interface ParametrosBusca {
  pageNumber: number,
  quantityOfElements: number
}

@Component({
  selector: 'tabela-listagem-casa-comunitaria-comp',
  templateUrl: './tabela-listagem-casa-comunitaria.component.html',
  styleUrls: ['./tabela-listagem-casa-comunitaria.component.css']
})
export class TabelaListagemCasaComunitariaComponent implements OnInit {

  @Input()
  public resultadoPesquisa : ResultadoPesquisaCasaComunitaria;
  @Output() 
  clickPaginacaoEvento = new EventEmitter<ParametrosBusca>();
  public parametrosBusca: ParametrosBusca;

  constructor() {
    this.parametrosBusca = { 
      pageNumber : 0, 
      quantityOfElements : 10
    };
  }

  ngOnInit() {
  }

  cliqueNoBotaoListener(botao) {
      this.parametrosBusca.pageNumber = botao.numero-1;
      this.clickPaginacaoEvento.emit(this.parametrosBusca);
  }

}
