import { NgModule } from '@angular/core';
import { LeftSidebarAssistenteGovernoComponent } from './left-sidebar-assistente-governo.component';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { GenericService } from 'src/app/core/services/generic.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ 
        LeftSidebarAssistenteGovernoComponent
    ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [
        LeftSidebarAssistenteGovernoComponent
    ],
    providers: [
        UsuarioService,
        GenericService
    ]
})
export class LeftSidebarAssistenteGovernoModule {

}