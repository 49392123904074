import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class ListagemAcompanhamentoCasaComunitariaComponent {
    constructor(obterAcompanhamentosService, swtAlertService) {
        this.obterAcompanhamentosService = obterAcompanhamentosService;
        this.swtAlertService = swtAlertService;
        this.$destroy = new Subject();
        this.listaAcompanhamento = new Array();
        this.busca = {
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    ngOnInit() {
        this.carregarAcompanhamentosPorCasaComunitaria();
    }
    ngOnDestroy() {
        this.$destroy.next();
        this.$destroy.complete();
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarAcompanhamentosPorCasaComunitaria();
    }
    carregarAcompanhamentosPorCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.obterAcompanhamentosService
                .executar(this.casaComunitariaId)
                .pipe(takeUntil(this.$destroy))
                .subscribe({
                next: (response) => {
                    this.totalDePaginas = response.totalPages;
                    this.listaAcompanhamento = response.content;
                },
                error: (error) => this.swtAlertService.errorAlert(error.errors),
            });
        });
    }
    converterPeriodoEmTexto(periodo) {
        switch (periodo) {
            case "MARCO_MAIO":
                return "De Março a Maio";
            case "JUNHO_AGOSTO":
                return "De Junho a Agosto";
            case "SETEMBRO_NOVEMBRO":
                return "De Setembro a Novembro";
            case "DEZEMBRO_FEVEREIRO":
                return "De Dezembro a Fevereiro";
            default:
                return "Não foi definido o período";
        }
    }
}
