import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
export class VisualizarEquipeFormacaoComponent {
    constructor(missionarioMissaoAtualService, imagemService, swtAlert2Service) {
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.imagemService = imagemService;
        this.swtAlert2Service = swtAlert2Service;
    }
    ngOnInit() {
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarEquipeFormacaoCasaComunitaria();
        }))();
    }
    carregarEquipeFormacaoCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.findEquipeFormacaoPorCasaComunitaria(this.casaComunitariaId);
                this.listaEquipeFormacao = response.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                for (let i = 0; i < this.listaEquipeFormacao.length; i++) {
                    if (this.listaEquipeFormacao[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.listaEquipeFormacao[i].imagemId);
                        this.imagemService.createImage(blogImage, this.listaEquipeFormacao[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
