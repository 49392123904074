import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class AtualizarAcompanhamentoCasaComunitariaComponent {
    constructor(route, obterAcompanhamentoService, atualizarAcompanhamentoService, swtAlertService, router) {
        this.route = route;
        this.obterAcompanhamentoService = obterAcompanhamentoService;
        this.atualizarAcompanhamentoService = atualizarAcompanhamentoService;
        this.swtAlertService = swtAlertService;
        this.router = router;
        this.$destroy = new Subject();
        this.acompanhamentoCasaComunitaria =
            {};
    }
    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.acompanhamentoCasaComunitariaId = Number(params.get("id"));
            this.carregarDadosAcompanhamentoCasaComunitaria();
        });
    }
    ngOnDestroy() {
        this.$destroy.next();
        this.$destroy.complete();
    }
    atualizarAcompanhamentoCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.atualizarAcompanhamentoService
                .executar(this.acompanhamentoCasaComunitariaId, this.acompanhamentoCasaComunitaria)
                .subscribe({
                next: () => {
                    this.swtAlertService.successAlert("Acompanhamento atualizado com sucesso!");
                    setTimeout(() => {
                        this.router.navigate([
                            "/secured/acc/visualizar/" +
                                this.acompanhamentoCasaComunitariaId,
                        ]);
                    }, 5000);
                },
                error: (error) => this.swtAlertService.errorAlert(error.errors),
            });
        });
    }
    carregarDadosAcompanhamentoCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.obterAcompanhamentoService
                .executar(this.acompanhamentoCasaComunitariaId)
                .pipe(takeUntil(this.$destroy))
                .subscribe({
                next: (response) => (this.acompanhamentoCasaComunitaria = response),
            });
        });
    }
    converterPeriodoEmTexto(periodo) {
        switch (periodo) {
            case "MARCO_MAIO":
                return "De Março a Maio";
            case "JUNHO_AGOSTO":
                return "De Junho a Agosto";
            case "SETEMBRO_NOVEMBRO":
                return "De Setembro a Novembro";
            case "DEZEMBRO_FEVEREIRO":
                return "De Dezembro a Fevereiro";
            default:
                return "Não foi definido o período";
        }
    }
}
