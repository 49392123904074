import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { SearchCelulaType } from 'src/app/secured/wop/celula/celulas.component';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';
import { CentroEvangelizacaoService } from 'src/app/secured/wop/centroevangelizacao/service/centroevangelizacao.service';
import { EstadoService } from 'src/app/secured/wop/estado/service/estado.service';
import { ElementoGrupoDominioService } from 'src/app/secured/wop/grupodominio/service/elementogrupodominio.service';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';
import { PaisService } from 'src/app/secured/wop/pais/service/pais.service';
import { RegionalService } from 'src/app/secured/wop/regional/service/regional.service';
import { DetalhesDashboardCelula } from 'src/app/model/DetalhesDashboardCelula.model';
import { Subject } from 'rxjs';
import { ResumoCelulaService } from '../../../assistente-formacao/service/ResumoCelulaService.service';
import { LoggedInUserSharedDataService } from 'src/app/secured/layout/header/service/logged-in-user-shared-data.service';
import { takeUntil } from 'rxjs/operators';
import { Pessoa } from 'src/app/model/pessoa.model';

@Component({
  selector: 'search-celula-rl',
  templateUrl: './search-celula.rl.component.html',
  styleUrls: ['./search-celula.rl.component.scss'],
})
export class SearchCelulaResponsavelLocalComponent
  implements OnInit, OnDestroy
{
  private pessoa: Pessoa;

  public regionais: Array<Regional>;
  public missoes: Array<Missao>;

  public fases: Array<ElementoGrupoDominio>;
  public centrosEvangelizacao: Array<CentroEvangelizacao>;
  public celulas: Array<Celula>;
  public regionalSelecionadoId: number;
  public missaoSelecionadoId: number;
  public busca: SearchCelulaType;
  public totalDePaginas: number;
  detalhesDashboardCelula: DetalhesDashboardCelula;
  unsubscribe$ = new Subject<void>();

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    public resumoCelulaService: ResumoCelulaService,
    public regionalService: RegionalService,
    public missaoService: MissaoService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public centroEvangelizacaoService: CentroEvangelizacaoService,
    public celulaService: CelulaService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.fases = new Array<ElementoGrupoDominio>();
    this.centrosEvangelizacao = new Array<CentroEvangelizacao>();

    this.busca = {
      nome: null,
      ano: null,
      faseId: null,
      regionalId: null,
      missaoId: null,
      centroEvangelizacaoId: null,
      paisId: null,
      estadoId: null,
      encerrada: null,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  ngOnInit() {
    this.loggedInUserSharedDataService.loggedInPerson$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (person) => {
          this.pessoa = person;
          this.busca.missaoId =
            person && person.vinculoMissao.missaoAtualId
              ? person.vinculoMissao.missaoAtualId
              : null;
          if (this.busca.missaoId)
            this.carregarCevPorMissao(this.busca.missaoId);

          this.initializer();
          this.carregarDashboard();
        },
      });
  }

  carregarDashboard() {
    this.resumoCelulaService
      .obterDashboard(this.busca)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => (this.detalhesDashboardCelula = response),
        error: (error) => this.swtAlert2Service.errorAlert(error.error.errors),
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async initializer(): Promise<any> {
    this.carregarRegionais();
    this.carregarNiveisDaCelula();
    this.buscarCelulas();
  }

  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero - 1;
    this.buscarCelulas();
  }

  async carregarRegionais(): Promise<any> {
    try {
      let response = await this.regionalService.findAll();
      this.regionais = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMissoes(): Promise<any> {
    try {
      let response = await this.missaoService.porRegional(
        this.busca.regionalId
      );
      this.missoes = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarCevPorMissao(missaoId: number): void {
    this.centroEvangelizacaoService
      .porMissao(missaoId)
      .then((response: any) => {
        this.centrosEvangelizacao = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarCevPorDifusao(difusaoId: number): void {
    this.centroEvangelizacaoService
      .porDifusao(difusaoId)
      .then((response: any) => {
        this.centrosEvangelizacao = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarNiveisDaCelula(): void {
    let buscaFase = {
      grupoNome: 'FASE_CELULA',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFase)
      .then((lista: any) => {
        this.fases = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  buscarCelulas(): void {
    this.celulaService
      .buscarCelulas(this.busca)
      .then((response: any) => {
        this.celulas = response.entity;
        this.totalDePaginas = response.quantity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
    this.carregarDashboard();
  }

  trackById(index: number, item: Celula) {
    return item.id;
  }
}
