<div class="card card-gray-dark color-pallete-box">
  <div class="card-header">
    <h3 class="card-title">
      <i class="fas fa-file-alt"></i> Formulário de Acompanhamento
    </h3>
  </div>
  <form #formulario="ngForm" (submit)="adicionarAcompanhamento()">
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4">
          <div class="form-group">
            <label for="ano">Ano Formativo:</label>
            <br>
            <input id="ano" name="ano" class="form-control" type="number" [min]="anoAtual-1" (blur)="validarAno($event)"
              [(ngModel)]="dadosCadastroAcompanhamento.ano" required #ano="ngModel" />
            <div *ngIf="(formulario.submitted && !dadosCadastroAcompanhamento.ano) || (ano.invalid && ano.touched)"
              style="color: red;">
              Este campo é obrigatório.
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
          <div class="form-group">
            <label for="periodo">Período do Acompanhamento:</label>
            <select id="periodo" name="periodo" class="form-control"
              [(ngModel)]="dadosCadastroAcompanhamento.periodoAcompanhamento" required #periodo="ngModel">
              <option [value]="undefined">Selecione um período</option>
              <option [value]="'MARCO_MAIO'">De Março a Maio</option>
              <option [value]="'JUNHO_AGOSTO'">De Junho a Agosto</option>
              <option [value]="'SETEMBRO_NOVEMBRO'">De Setembro a Novemnto</option>
              <option [value]="'DEZEMBRO_FEVEREIRO'">De Dezembro a Fevereiro</option>
            </select>
            <div
              *ngIf="(formulario.submitted && !dadosCadastroAcompanhamento.periodoAcompanhamento) || (periodo.invalid && periodo.touched)"
              style="color: red;">
              Este campo é obrigatório.
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
          <div class="form-group">
            <label for="data">Data do Acompanhamento:</label>
            <input id="data" name="data" type="date" class="form-control"
              [(ngModel)]="dadosCadastroAcompanhamento.dataAcompanhamento" required #data="ngModel" />
            <div
              *ngIf="(formulario.submitted && !dadosCadastroAcompanhamento.dataAcompanhamento) || (data.invalid && data.touched)"
              style="color: red;">
              Este campo é obrigatório.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="partilha-formador">Partilha do formador:</label>
            <textarea id="partilha-formador" class="form-control"
              [(ngModel)]="dadosCadastroAcompanhamento.partilhaFormador" name="partilha-formador" rows="4"
              placeholder="Digite aqui..."></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="orientacoes-dadas">Orientações dadas:</label>
            <textarea id="orientacoes-dadas" class="form-control"
              [(ngModel)]="dadosCadastroAcompanhamento.orientacoesDadas" name="orientacoes-dadas" rows="4"
              placeholder="Digite aqui..."></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row justify-content-end">
        <button class="btn btn-primary" type="submit">Salvar Acompanhamento</button>
      </div>
    </div>
  </form>
</div>