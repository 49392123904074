import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CasaComunitariaLocalManageComponent } from "./casacomunitaria.manager.component";
import { CasasComunitariasListarComponent } from "./casascomunitarias.listar.component";
import { PesquisaCasaComunitariaComponent } from "./components/pesquisa-casa-comunitaria/pesquisa-casa-comunitaria.component";
import { AdicaoAcompanhamentoCasaComunitariaComponent } from "./components/adicao-acompanhamento-casa-comunitaria/adicao-acompanhamento-casa-comunitaria.component";
import { VisualizarDetalhesCompletosCasaComunitariaComponent } from "./components/visualizar-detalhes-completos-casa-comunitaria/visualizar-detalhes-completos-casa-comunitaria.component";
import { VisualizarAcompanhamentoCasaComunitariaComponent } from "./components/visualizar-acompanhamento-casa-comunitaria/visualizar-acompanhamento-casa-comunitaria.component";
import { AtualizarAcompanhamentoCasaComunitariaComponent } from "./components/atualizar-acompanhamento-casa-comunitaria/atualizar-acompanhamento-casa-comunitaria.component";

const casacomunitariaRoutes: Routes = [
	{
		path: "secured/local/casacomunitaria/gerenciar/:id",
		component: CasaComunitariaLocalManageComponent,
	},
	{
		path: "secured/local/casacomunitaria/gerenciamento",
		component: CasaComunitariaLocalManageComponent,
	},
	{
		path: "secured/rl/casascomunitarias",
		component: CasasComunitariasListarComponent,
	},
	{
		path: "secured/acc/casascomunitarias",
		component: PesquisaCasaComunitariaComponent,
	},
	{
		path: "secured/acc/visualizardetalhescompletos/:id",
		component: VisualizarDetalhesCompletosCasaComunitariaComponent,
	},
	{
		path: "secured/acc/adicaoacompanhamento/:id",
		component: AdicaoAcompanhamentoCasaComunitariaComponent,
	},
	{
		path: "secured/acc/visualizar/:id",
		component: VisualizarAcompanhamentoCasaComunitariaComponent,
	},
	{
		path: "secured/acc/editar/:id",
		component: AtualizarAcompanhamentoCasaComunitariaComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(casacomunitariaRoutes)],
	exports: [RouterModule],
})
export class CasaComunitariaLocalRoutingModule {}
