import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class SearchCelulaResponsavelLocalComponent {
    constructor(loggedInUserSharedDataService, resumoCelulaService, regionalService, missaoService, paisService, estadoService, centroEvangelizacaoService, celulaService, elementoGrupoDominioService, swtAlert2Service) {
        this.loggedInUserSharedDataService = loggedInUserSharedDataService;
        this.resumoCelulaService = resumoCelulaService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.celulaService = celulaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.fases = new Array();
        this.centrosEvangelizacao = new Array();
        this.busca = {
            nome: null,
            ano: null,
            faseId: null,
            regionalId: null,
            missaoId: null,
            centroEvangelizacaoId: null,
            paisId: null,
            estadoId: null,
            encerrada: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    ngOnInit() {
        this.loggedInUserSharedDataService.loggedInPerson$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (person) => {
                this.pessoa = person;
                this.busca.missaoId =
                    person && person.vinculoMissao.missaoAtualId
                        ? person.vinculoMissao.missaoAtualId
                        : null;
                if (this.busca.missaoId)
                    this.carregarCevPorMissao(this.busca.missaoId);
                this.initializer();
                this.carregarDashboard();
            },
        });
    }
    carregarDashboard() {
        this.resumoCelulaService
            .obterDashboard(this.busca)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (response) => (this.detalhesDashboardCelula = response),
            error: (error) => this.swtAlert2Service.errorAlert(error.error.errors),
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarRegionais();
            this.carregarNiveisDaCelula();
            this.buscarCelulas();
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarCelulas();
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(this.busca.regionalId);
                this.missoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCevPorMissao(missaoId) {
        this.centroEvangelizacaoService
            .porMissao(missaoId)
            .then((response) => {
            this.centrosEvangelizacao = response.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCevPorDifusao(difusaoId) {
        this.centroEvangelizacaoService
            .porDifusao(difusaoId)
            .then((response) => {
            this.centrosEvangelizacao = response.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarNiveisDaCelula() {
        let buscaFase = {
            grupoNome: 'FASE_CELULA',
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFase)
            .then((lista) => {
            this.fases = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    buscarCelulas() {
        this.celulaService
            .buscarCelulas(this.busca)
            .then((response) => {
            this.celulas = response.entity;
            this.totalDePaginas = response.quantity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
        this.carregarDashboard();
    }
    trackById(index, item) {
        return item.id;
    }
}
