<div class="row">
  <div class="col-md-12">
    <div class="card card-gray-dark color-pallete-box">
      <div class="card-header">
        <h3 class="card-title">
          <i class="fas fa-people"></i>
          <a i18n> Membros da Equipe de Formação </a>
        </h3>
        <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i
              class="fas fa-minus"></i></button></div>
      </div>
      <div class="card-body overflow-auto table-responsive p-0">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Foto de perfil</th>
              <th>Nome</th>
              <th>Data de Entrada na Casa Comunitária</th>
              <th>Forma de Vida</th>
              <th>Nível Formativo</th>
              <th>Estado de Vida</th>
              <th>Autoridade</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let membro of listaEquipeFormacao">
              <td>
                <div class="widget-user-image">
                  <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                    [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="User Avatar">
                </div>
              </td>
              <td>{{membro.pessoaNome}}</td>
              <td>{{membro.dataChegadaCasaComunitaria | date : 'dd/MM/yyyy'}}</td>
              <td>{{membro.formaVidaNome}}</td>
              <td>{{membro.nivelFormativoNome}}</td>
              <td>{{membro.estadoVidaNome}}</td>
              <td>{{membro.apostoladoAutoridadeNome}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>