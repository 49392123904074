import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { CasaComunitaria } from "src/app/model/casacomunitaria.model";

@Component({
	selector: "row-listagem-casa-comunitaria-comp",
	templateUrl: "./row-listagem-casa-comunitaria.component.html",
	styleUrls: ["./row-listagem-casa-comunitaria.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class RowListagemCasaComunitariaComponent implements OnInit {
	constructor() {}

	@Input()
	casaComunitaria: CasaComunitaria;

	ngOnInit() {}
}
