import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
export class ParecerCelulaComponent {
    constructor(missaoConfiguracaoService, questionarioConfigService, usuarioService, imagemService, pessoaService, celulaService, membroService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.questionarioConfigService = questionarioConfigService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.buscaMembros = {
            celulaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadorComunitario();
            if (this.formadorComunitario != undefined &&
                this.formadorComunitario.casaCelulaId != null) {
                yield this.carregarCelula();
                this.buscaMembros = {
                    celulaId: this.celula.id,
                    pageNumber: 0,
                    quantityOfElements: 4,
                };
                yield this.carregarMembrosCelula();
            }
            else
                this.hasCelulaAssociada = false;
        });
    }
    carregarQuestionarioConfiguracao(questionarioId) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.questionarioConfigService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
                /*if(this.pessoa.imagemId != null)
                  {
                      let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);
          
                      this.imagemService.createImage(blogImage, this.pessoa);
                  } */
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id);
                this.formadorComunitario = response;
                /*if(this.formadorComunitario.pessoaImagemId != null)
                  {
                      let blogImage = await this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);
          
                      this.imagemService.createImage(blogImage, this.formadorComunitario);
                  } */
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.find(this.formadorComunitario.casaCelulaId);
                this.celula = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.listarMembrosCelula(this.celula.id);
                this.membrosCelula = response;
                this.totalDePaginasMembros = 1;
                //this.membrosCelula = response.content;
                //this.totalDePaginasMembros = response.totalPages;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.membrosCelula.length; i++) {
                    if (this.membrosCelula[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                    }
                    let questionarioFormando = yield this.carregarQuestionario(this.membrosCelula[i].pessoaId);
                    if (questionarioFormando != null) {
                        if (!this.questionarioConfiguracao) {
                            this.carregarQuestionarioConfiguracao(questionarioFormando.questionarioId);
                        }
                        this.membrosCelula[i].statusQuestionario =
                            questionarioFormando.status;
                        this.membrosCelula[i].idQuestionario = questionarioFormando.id;
                        let parecerResponsavelAtual = yield this.carregarParecer(questionarioFormando.id);
                        if (parecerResponsavelAtual != null) {
                            this.membrosCelula[i].statusParecer =
                                parecerResponsavelAtual.status;
                            this.membrosCelula[i].idParecer = parecerResponsavelAtual.id;
                        }
                        else {
                            this.membrosCelula[i].statusParecer = 'Não iniciado';
                            this.membrosCelula[i].idParecer = null;
                        }
                    }
                    if (questionarioFormando == null) {
                        this.membrosCelula[i].statusQuestionario = 'Não iniciado';
                        this.membrosCelula[i].idQuestionario = null;
                    }
                    console.log(this.membrosCelula);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionario(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
                if (response != undefined && response != null) {
                    return response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecer(idQuestionario) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscarPorQuestionarioFormandoId(idQuestionario);
                return response;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let responsePorPessoa = yield this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
                if (responsePorPessoa != undefined &&
                    responsePorPessoa != null &&
                    responsePorPessoa.questionarioId != 3) {
                    this.questionarioFormando = responsePorPessoa;
                    this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                }
                else {
                    this.questionarioFormando.anoFormativoId = 2;
                    this.questionarioFormando.questionarioId = 3;
                    this.questionarioFormando.pessoaId = pessoaId;
                    let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                    if (response != undefined && response != null) {
                        this.questionarioFormando = response;
                        this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerCAL(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 2;
                this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate([
                        '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    consultarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (formando.idQuestionario != null) {
                    let response = yield this.parecerResponsavelService.buscarPorQuestionarioFormandoId(formando.idQuestionario);
                    if (response != undefined && response != null) {
                        this.parecerResponsavel = response;
                        // this.router.navigate(['/secured/parecer-cal-answer/' + this.parecerResponsavel.id]);
                        this.router.navigate([
                            '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
                        ]);
                    }
                    else {
                        this.cadastrarVinculoParecerCAL(formando.idQuestionario);
                    }
                }
                if (formando.idQuestionario == null) {
                    this.cadastrarVinculoQuestionarioCAL(formando.pessoaId);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCelula();
    }
}
