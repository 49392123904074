import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CasaComunitariaService } from "../../wop/casacomunitaria/service/casacomunitaria.service";
import { CommonModule } from "@angular/common";
import { SecuredModule } from "../../layout/secured.module";
import { RouterModule } from "@angular/router";
import { CasaComunitariaLocalRoutingModule } from "./casacomunitaria-routing.module";
import { CasaComunitariaLocalManageComponent } from "./casacomunitaria.manager.component";
import { AdicaoMembroCasaComunitariaModule } from "./components/adicao-membro-casa-comunitaria/adicao-membro-casa-comunitaria.module";
import { IgxTabsModule } from "igniteui-angular";
import { ImageCropperModule } from "ngx-image-cropper";
import { CasasComunitariasListarComponent } from "./casascomunitarias.listar.component";
import { PesquisaCasaComunitariaComponent } from "./components/pesquisa-casa-comunitaria/pesquisa-casa-comunitaria.component";
import { TabelaListagemCasaComunitariaComponent } from "./components/tabela-listagem-casa-comunitaria/tabela-listagem-casa-comunitaria.component";
import { AdicaoAcompanhamentoCasaComunitariaComponent } from "./components/adicao-acompanhamento-casa-comunitaria/adicao-acompanhamento-casa-comunitaria.component";
import { ExibirDetalhesCasaComunitariaComponent } from "./components/visualizar-detalhes-casa-comunitaria/exibir-detalhes-casa-comunitaria.component";
import { FormularioAcompanhamentoCasaComunitariaComponent } from "./components/formulario-acompanhamento-casa-comunitaria/formulario-acompanhamento-casa-comunitaria.component";
import { RowListagemCasaComunitariaComponent } from "./components/tabela-listagem-casa-comunitaria/row-listagem-casa-comunitaria/row-listagem-casa-comunitaria.component";
import { VisualizarDetalhesCompletosCasaComunitariaComponent } from "./components/visualizar-detalhes-completos-casa-comunitaria/visualizar-detalhes-completos-casa-comunitaria.component";
import { ListagemMembrosCasaComunitariaComponent } from "./components/listagem-membros-casa-comunitaria/listagem-membros-casa-comunitaria.component";
import { ListagemAcompanhamentoCasaComunitariaComponent } from "./components/listagem-acompanhamento-casa-comunitaria/listagem-acompanhamento-casa-comunitaria.component";
import { VisualizarAcompanhamentoCasaComunitariaComponent } from "./components/visualizar-acompanhamento-casa-comunitaria/visualizar-acompanhamento-casa-comunitaria.component";
import { AtualizarAcompanhamentoCasaComunitariaComponent } from "./components/atualizar-acompanhamento-casa-comunitaria/atualizar-acompanhamento-casa-comunitaria.component";
import { VisualizarEquipeFormacaoComponent } from "./components/visualizar-equipe-formacao/visualizar-equipe-formacao.component";
import { AdicaoEquipeFormacaoComponent } from "./components/adicao-equipe-formacao-casa-comunitaria/adicao-equipe-formacao.component";

@NgModule({
	declarations: [
		PesquisaCasaComunitariaComponent,
		TabelaListagemCasaComunitariaComponent,
		RowListagemCasaComunitariaComponent,
		AdicaoAcompanhamentoCasaComunitariaComponent,
		ExibirDetalhesCasaComunitariaComponent,
		VisualizarAcompanhamentoCasaComunitariaComponent,
		AtualizarAcompanhamentoCasaComunitariaComponent,
		VisualizarDetalhesCompletosCasaComunitariaComponent,
		ListagemMembrosCasaComunitariaComponent,
		ListagemAcompanhamentoCasaComunitariaComponent,
		FormularioAcompanhamentoCasaComunitariaComponent,
		CasaComunitariaLocalManageComponent,
		CasasComunitariasListarComponent,
		VisualizarEquipeFormacaoComponent,
		AdicaoEquipeFormacaoComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		CasaComunitariaLocalRoutingModule,
		FormsModule,
		SecuredModule,
		IgxTabsModule,
		ImageCropperModule,
		AdicaoMembroCasaComunitariaModule,
	],
	providers: [CasaComunitariaService],
})
export class CasaComunitariaLocalModule {}
