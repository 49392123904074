<table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'"
    [tituloSessao]="'Listagem de Missionários'" [cols]="11" [rows]="busca.quantityOfElements"
    [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
    <tr tableheader>
        <th>Foto de perfil</th>
        <th>Nome</th>
        <th>Data de Entrada na Casa Comunitária</th>
        <th>Forma de Vida</th>
        <th>Nível Formativo</th>
        <th>Estado de Vida</th>
    </tr>
    <tr tablebody *ngFor="let membro of membrosCasaComunitaria">
        <td>
            <div class="widget-user-image">
                <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                    [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="User Avatar">
            </div>
        </td>
        <td>{{membro.pessoaNome}}</td>
        <td>{{membro.dataChegadaCasaComunitaria | date : 'dd/MM/yyyy'}}</td>
        <td>{{membro.formaVidaNome}}</td>
        <td>{{membro.nivelFormativoNome}}</td>
        <td>{{membro.estadoVidaNome}}</td>
    </tr>
</table-component>