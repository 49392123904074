<table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'"
  [tituloSessao]="'Listagem dos Acompanhamentos da Casa Comunitária'" [cols]="11" [rows]="busca.quantityOfElements"
  [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
  <tr tableheader>
    <th>#</th>
    <th>Ano</th>
    <th>Período Acompanhamento</th>
    <th>Acompanhador</th>
    <th>Formador</th>
    <th>Data do Acompanhamento</th>
    <th></th>
  </tr>
  <tr tablebody *ngFor="let acompanhamento of listaAcompanhamento">
    <td>{{acompanhamento.id}}</td>
    <td>{{acompanhamento.ano}}</td>
    <td>{{converterPeriodoEmTexto(acompanhamento.periodoAcompanhamento)}}</td>
    <td>{{acompanhamento.acompanhadorPessoaNome}}</td>
    <td>{{acompanhamento.formadorPessoaNome}}</td>
    <td>{{acompanhamento.dataAcompanhamento | date: 'dd/MM/yyyy'}}</td>
    <td>
      <button class="btn btn-sm btn-secondary ml-1" routerLink="../../visualizar/{{acompanhamento.id}}"
        title="Visualizar Detalhes do Acompanhamento" alt="Visualizar Detalhes do Acompanhamento"><i
          class="fas fa-eye"></i></button>
      <button class="btn btn-sm btn-primary ml-1" routerLink="../../editar/{{acompanhamento.id}}"
        title="Editar Acompanhamento" alt="Editar Acompanhamento"><i class="fas fa-edit"></i></button>
    </td>
  </tr>
</table-component>