import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AcompanhamentoCasaComunitaria } from "src/app/model/acompanhamentocasacomunitaria.model";
import { ObterAcompanhamentoCasaComunitariaServiceService } from "../visualizar-acompanhamento-casa-comunitaria/ObterAcompanhamentoCasaComunitariaService.service";
import { AtualizarAcompanhamentoCasaComunitariaService } from "./atualizar-acompanhamento-casa-comunitaria.service";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";

@Component({
	selector: "app-atualizar-acompanhamento-casa-comunitaria",
	templateUrl: "./atualizar-acompanhamento-casa-comunitaria.component.html",
	styleUrls: ["./atualizar-acompanhamento-casa-comunitaria.component.css"],
})
export class AtualizarAcompanhamentoCasaComunitariaComponent implements OnInit {
	private $destroy = new Subject<void>();
	acompanhamentoCasaComunitariaId: number;
	public acompanhamentoCasaComunitaria: AcompanhamentoCasaComunitaria;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly obterAcompanhamentoService: ObterAcompanhamentoCasaComunitariaServiceService,
		private readonly atualizarAcompanhamentoService: AtualizarAcompanhamentoCasaComunitariaService,
		private readonly swtAlertService: SwtAlert2Service,
		private readonly router: Router
	) {
		this.acompanhamentoCasaComunitaria =
			{} as AcompanhamentoCasaComunitaria;
	}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.acompanhamentoCasaComunitariaId = Number(params.get("id"));
			this.carregarDadosAcompanhamentoCasaComunitaria();
		});
	}

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	async atualizarAcompanhamentoCasaComunitaria() {
		this.atualizarAcompanhamentoService
			.executar(
				this.acompanhamentoCasaComunitariaId,
				this.acompanhamentoCasaComunitaria
			)
			.subscribe({
				next: () => {
					this.swtAlertService.successAlert(
						"Acompanhamento atualizado com sucesso!"
					);
					setTimeout(() => {
						this.router.navigate([
							"/secured/acc/visualizar/" +
								this.acompanhamentoCasaComunitariaId,
						]);
					}, 5000);
				},
				error: (error) => this.swtAlertService.errorAlert(error.errors),
			});
	}

	async carregarDadosAcompanhamentoCasaComunitaria() {
		this.obterAcompanhamentoService
			.executar(this.acompanhamentoCasaComunitariaId)
			.pipe(takeUntil(this.$destroy))
			.subscribe({
				next: (response) =>
					(this.acompanhamentoCasaComunitaria = response),
			});
	}

	converterPeriodoEmTexto(periodo: string) {
		switch (periodo) {
			case "MARCO_MAIO":
				return "De Março a Maio";
			case "JUNHO_AGOSTO":
				return "De Junho a Agosto";
			case "SETEMBRO_NOVEMBRO":
				return "De Setembro a Novembro";
			case "DEZEMBRO_FEVEREIRO":
				return "De Dezembro a Fevereiro";
			default:
				return "Não foi definido o período";
		}
	}
}
