import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
export class AdicaoAcompanhamentoCasaComunitariaComponent {
    constructor(casaComunitariaService, route, swtAlert2Service) {
        this.casaComunitariaService = casaComunitariaService;
        this.route = route;
        this.swtAlert2Service = swtAlert2Service;
    }
    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.casaComunitariaId = Number(params.get("id"));
        });
    }
    carregarCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.find(this.casaComunitariaId);
            }
            catch (error) {
                this.swtAlert2Service.errorAlert(error.errors);
            }
        });
    }
}
