import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'select2';
import { environment } from "src/environments/environment";
import intlTelInput from 'intl-tel-input';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
export class MissionarioDetalhadoViewComponent {
    constructor(pessoaService, enderecoService, imagemService, elementoGrupoDominioService, regionalService, missaoService, difusaoService, membroComunidadeAliancaService, membroComunidadeVidaService, escolaridadeService, trabalhoService, aptidaoService, documentoService, swtAlert2Service, vistoService, vistoBuilder, pessoaNacionalidadeService, pessoaNacionalidadeBuilder, nacionalidadeService, casaComunitariaService, usuarioService, paisService, filhoService, familiaInfoService, familiaInfoBuilder, dadoVocacionalService, celibatarioService, apostoladoService, historicoMissionarioMissaoService, historicoMissionarioMissaoApostoladoService, relacionamentoService, sacerdoteService, pessoaInfoBuilder, filhoBuilder, dadoVocacionalBuilder, celibatarioBuilder, apostoladoBuilder, historicoMissionarioMissaoBuilder, historicoMissionarioMissaoApostoladoBuilder, relacionamentoBuilder, sacerdoteBuilder, route, router) {
        this.pessoaService = pessoaService;
        this.enderecoService = enderecoService;
        this.imagemService = imagemService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.membroComunidadeAliancaService = membroComunidadeAliancaService;
        this.membroComunidadeVidaService = membroComunidadeVidaService;
        this.escolaridadeService = escolaridadeService;
        this.trabalhoService = trabalhoService;
        this.aptidaoService = aptidaoService;
        this.documentoService = documentoService;
        this.swtAlert2Service = swtAlert2Service;
        this.vistoService = vistoService;
        this.vistoBuilder = vistoBuilder;
        this.pessoaNacionalidadeService = pessoaNacionalidadeService;
        this.pessoaNacionalidadeBuilder = pessoaNacionalidadeBuilder;
        this.nacionalidadeService = nacionalidadeService;
        this.casaComunitariaService = casaComunitariaService;
        this.usuarioService = usuarioService;
        this.paisService = paisService;
        this.filhoService = filhoService;
        this.familiaInfoService = familiaInfoService;
        this.familiaInfoBuilder = familiaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.celibatarioService = celibatarioService;
        this.apostoladoService = apostoladoService;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.historicoMissionarioMissaoApostoladoService = historicoMissionarioMissaoApostoladoService;
        this.relacionamentoService = relacionamentoService;
        this.sacerdoteService = sacerdoteService;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.filhoBuilder = filhoBuilder;
        this.dadoVocacionalBuilder = dadoVocacionalBuilder;
        this.celibatarioBuilder = celibatarioBuilder;
        this.apostoladoBuilder = apostoladoBuilder;
        this.historicoMissionarioMissaoBuilder = historicoMissionarioMissaoBuilder;
        this.historicoMissionarioMissaoApostoladoBuilder = historicoMissionarioMissaoApostoladoBuilder;
        this.relacionamentoBuilder = relacionamentoBuilder;
        this.sacerdoteBuilder = sacerdoteBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.regionais = new Array();
        this.missoesVinculo = new Array();
        this.difusoesVinculo = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.escolaridades = new Array();
        this.trabalhos = new Array();
        this.estadosCivil = new Array();
        this.tiposDocumento = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.habilidades = new Array();
        this.linguas = new Array();
        this.niveisEscolaridade = new Array();
        this.pessoaNacionalidades = new Array();
        this.nacionalidades = new Array();
        this.vistos = new Array();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();
        this.pessoaNacionalidade = this.pessoaNacionalidadeBuilder.getInstance();
        this.familiaInfo = this.familiaInfoBuilder.getInstance();
        this.visto = this.vistoBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.regionalSelecionadoId = null;
        this.formaVidaSelecionadaId = null;
        this.estadoVidaSelecionadoId = null;
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.missaoSelecionadaId = null;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.filho = this.filhoBuilder.getInstance();
        this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
        this.dadoVocacional = this.dadoVocacionalBuilder.getInstance();
        this.celibatario = this.celibatarioBuilder.getInstance();
        this.apostolado = this.apostoladoBuilder.getInstance();
        this.historicoMissionarioMissao = this.historicoMissionarioMissaoBuilder.getInstance();
        this.historicoMissionarioMissaoApostolado = this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
        this.relacionamento = this.relacionamentoBuilder.getInstance();
        this.sacerdote = this.sacerdoteBuilder.getInstance();
        this.filhos = new Array();
        this.paises = new Array();
        this.apostolados = new Array();
        this.historicosMissionarioMissao = new Array();
        this.historicosMissionarioMissaoApostolados = new Array();
    }
    ngOnInit() {
        this.initializacao();
    }
    initializacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.pessoaId = Number(this.route.snapshot.paramMap.get('id'));
            yield this.getUsuarioAtual();
            yield this.carregarPaises();
            yield this.carregarFormasVida();
            yield this.carregarEstadosCivil();
            yield this.carregarNiveisFormacao();
            yield this.carregarEstadoVida();
            yield this.carregarLinguas();
            yield this.carregarHabilidades();
            yield this.carregarNiveisEscolaridade();
            yield this.carregarMissoesVinculo();
            yield this.carregarDifusoesVinculo();
            //this.carregarMissoes();
            this.carregarTipoRelacionamento();
            this.carregarApostolados();
            yield this.getUsuarioAtual();
            yield this.carregarDadoVocacional();
            yield this.carregarCelibatario();
            yield this.carregarRelacionamento();
            yield this.carregarSacerdote();
            yield this.carregarFamilia();
            this.carregarFilhos();
            this.carregarHistoricoMissionarioMissao();
            $("select[name='linguas-select']").select2({
                theme: "bootstrap4"
            });
            $("select[name='habilidades-select']").select2({
                theme: "bootstrap4"
            });
            yield this.carregarPessoa(this.pessoaId);
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarOrigem() {
        if (this.regionalSelecionadoId != undefined) {
            if (this.tipoOrigem == "M") {
                this.carregarMissoes();
            }
            else if (this.tipoOrigem == "D") {
                this.carregarDifusoes();
            }
        }
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.findAll();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDifusoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.difusaoService.findAll();
                this.difusoesVinculo = response.entity;
                this.difusoesVinculo = this.difusoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.missoes = response.entity;
            this.difusoes = null;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarDifusoes() {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.difusoes = response.entity;
            this.missoes = null;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPessoaNacionalidades(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaNacionalidadeService.porPessoaId(pessoaId);
                this.pessoaNacionalidades = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarNacionalidades() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.nacionalidadeService.findAll();
                this.nacionalidades = response.entity;
                this.nacionalidades = this.nacionalidades.sort((a, b) => a.descricao.localeCompare(b.descricao, 'fi'));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarVistos(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.vistoService.porPessoaId(pessoaId);
                this.vistos = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    ngAfterViewChecked() {
        if (!this.intlTelInputCalled && $('#pessoa-telefone').length == 1) {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: callback => {
                    fetch("https://ipapi.co/json")
                        .then(res => res.json())
                        .then(data => callback(data.country_code))
                        .catch(() => callback("us"));
                },
                utilsScript: 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
    }
    carregarPessoa(Id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.find(Id);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                if (this.pessoa.usuarioId != null) {
                    $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
                }
                if (this.pessoa.formaVidaId != null) {
                    this.setarFormaVidaSelecionada();
                }
                if (this.pessoa.aptidaoId != null) {
                    this.carregarAptidao(this.pessoa.aptidaoId);
                }
                if (this.pessoa.trabalhoIds.length > 0) {
                    this.encontrarTrabalhoPorPessoaId(this.pessoaId);
                }
                this.encontrarDocumentosPorPessoaId(this.pessoaId);
                this.formaVidaSelecionadaId = this.pessoa.formaVidaId;
                this.estadoVidaSelecionadoId = this.pessoa.estadoVidaId;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;
            if(this.membroComunidadeAlianca.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;
            if(this.membroComunidadeVida.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    } */
    carregarAptidao(aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aptidaoService.find(aptidaoId);
                this.aptidao = response.entity;
                this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
                $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            }
            return null;
        });
    }
    encontrarEscolaridadesPorAptidaoId(aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.escolaridadeService.findByAptidaoId(aptidaoId);
                this.escolaridades = response.entity;
            }
            catch (err) {
                console.log(err);
            }
            return null;
        });
    }
    encontrarTrabalhoPorPessoaId(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.trabalhoService.findByPessoaId(pessoaId);
                this.trabalhos = response.entity;
            }
            catch (err) {
                console.log(err);
            }
            return null;
        });
    }
    carregarTipoDocumento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaDocumentos = {
                grupoNome: 'TIPOS_DOCUMENTOS_PESSOA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos);
                this.tiposDocumento = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadosCivil() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaEstadosCivil = {
                grupoNome: 'ESTADO_CIVIL'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil);
                this.estadosCivil = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaFormaVida = {
                grupoNome: 'FORMA_VIDA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida);
                this.formasVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarFamilia() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.familiaInfoService.porPessoaId(this.pessoaId);
                if (response.entity != null) {
                    this.familiaInfo = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    encontrarDocumentosPorPessoaId(pessoaId) {
        this.documentoService.buscarPorPessoaId(pessoaId).then((response) => {
            this.documentos = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarNiveisFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'NIVEL_FORMACAO'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.niveisFormacao = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'ESTADO_VIDA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.estadosVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarHabilidades() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'HABILIDADE'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.habilidades = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarLinguas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'LINGUA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.linguas = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarNiveisEscolaridade() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'NIVEL_ESCOLARIDADE'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.niveisEscolaridade = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    get valorTipoDocumento() {
        let tipoSelecionado = this.tiposDocumento.filter((tipo) => {
            return (tipo.id == this.documento.elementoGrupoDominioTipoId);
        });
        return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log('Imagem carregada');
    }
    cropperReady() {
        console.log('Imagem cortada');
    }
    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');
    }
    setarFormaVidaSelecionada() {
        for (let i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }
    setarNivelEscolaridadeSelecionado() {
        for (let i = 0; i < this.niveisEscolaridade.length; i++) {
            if (this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId) {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias(missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(missaoId);
                this.casasComunitarias = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.pessoaId);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome = this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCelibatario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celibatarioService.porPessoaId(this.pessoaId);
                if (response.entity != undefined && response.entity != null) {
                    this.celibatario = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRelacionamento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.relacionamentoService.porPessoaId(this.pessoaId);
                if (response.entity != undefined && response.entity != null) {
                    this.relacionamento = response.entity;
                    this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
                    if (this.relacionamento.pessoa1Id == this.pessoaId) {
                        this.pessoaRelacionamentoSelecionada.nome = this.relacionamento.pessoa2Nome;
                    }
                    else if (this.relacionamento.pessoa2Id == this.pessoaId) {
                        this.pessoaRelacionamentoSelecionada.nome = this.relacionamento.pessoa1Nome;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarSacerdote() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.sacerdoteService.porPessoaId(this.pessoaId);
                if (response.entity != undefined && response.entity != null) {
                    this.sacerdote = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarHistoricoMissionarioMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.historicoMissionarioMissaoService.porPessoaId(this.pessoaId);
                if (response.entity != undefined && response.entity != null) {
                    this.historicosMissionarioMissao = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.historicoMissionarioMissaoApostoladoService.findByHistoricoMissionarioMissaoId(historicoMissionarioMissaoID);
                if (response.entity != undefined && response.entity != null) {
                    this.historicosMissionarioMissaoApostolados = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarTipoRelacionamento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'TIPO_RELACIONAMENTO'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.tiposRelacionamento = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                ativo: null
            };
            try {
                let response = yield this.apostoladoService.pesquisar(request);
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFilhos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = null;
                if (this.pessoa.genero == 'masculino') {
                    response = yield yield this.filhoService.porPaiId(this.pessoaId);
                }
                else if (this.pessoa.genero == 'feminino') {
                    response = yield yield this.filhoService.porMaeId(this.pessoaId);
                }
                if (response != null) {
                    this.filhos = response.entity;
                    if (this.filhos.length > 0) {
                        this.hasFilho = true;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    configModalHistoricoApostolado(historicoMissionarioMissaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId = historicoMissionarioMissaoId;
            this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
        });
    }
    textoStatus(pessoaNacionalidade) {
        let returnText = '';
        $('select[name=pessoa_nac_status]').find('option').each(function () {
            if ($(this).val() == pessoaNacionalidade.status) {
                returnText = $(this).text();
            }
        });
        return returnText;
    }
    textoNacionalidade(pessoaNacionalidade) {
        let returnText = '';
        $('select[name=pessoa_nac]').find('option').each(function () {
            if ($(this).val() == pessoaNacionalidade.nacionalidadeId) {
                returnText = $(this).text();
            }
        });
        return returnText;
    }
}
