import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Estado } from 'src/app/model/estado.model';
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { Regional } from 'src/app/model/regional.model';
import { CasaComunitariaService } from 'src/app/secured/wop/casacomunitaria/service/casacomunitaria.service';
import { CidadeService } from 'src/app/secured/wop/cidade/service/cidade.service';
import { EstadoService } from 'src/app/secured/wop/estado/service/estado.service';
import { ElementoGrupoDominioService } from 'src/app/secured/wop/grupodominio/service/elementogrupodominio.service';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';
import { PaisService } from 'src/app/secured/wop/pais/service/pais.service';
import { RegionalService } from 'src/app/secured/wop/regional/service/regional.service';
import { ParametrosBusca } from '../tabela-listagem-casa-comunitaria/tabela-listagem-casa-comunitaria.component';


export interface ResultadoPesquisaCasaComunitaria {
    casasComunitarias: Array<CasaComunitaria>,
    totalDePaginas: number
}

@Component({
  selector: 'pesquisa-casa-comunitaria-comp',
  templateUrl: './pesquisa-casa-comunitaria.component.html',
  styleUrls: ['./pesquisa-casa-comunitaria.component.css']
})
export class PesquisaCasaComunitariaComponent implements OnInit {

    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public cidades: Array<Cidade>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public casasComunitarias: Array<CasaComunitaria>;
    public statusCasaComunitaria: Array<ElementoGrupoDominio>;
    public busca: any;
    public totalDePaginas: number;
    public resultadoCasasComunitarias: ResultadoPesquisaCasaComunitaria;


    constructor(
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public casaComunitariaService: CasaComunitariaService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) {
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
        this.cidades = new Array<Cidade>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.casasComunitarias = new Array<CasaComunitaria>();   
        this.statusCasaComunitaria = new Array<ElementoGrupoDominio>();        
        this.busca = {            
            nome: null,
            ano: null,
            regionalId: null,
            missaoId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            statusId: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarRegionais(); 
        this.carregarPaises(); 
        this.carregarStatusCasaComunitaria();
        this.buscarCasasComunitarias();
    }

    async carregarStatusCasaComunitaria() : Promise<null> {

        try{
            let request = {
                grupoNome : 'CASA_COMUNITARIA_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusCasaComunitaria = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => { 
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.busca.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.busca.estadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    buscarCasasComunitarias(parametrosBusca: ParametrosBusca = undefined) : void {
        if(parametrosBusca)
        {
            this.busca.pageNumber = parametrosBusca.pageNumber;
            this.busca.quantityOfElements = parametrosBusca.quantityOfElements;
        }
            
        this.casaComunitariaService.buscarCasasComunitaria(this.busca).then( (response: any) => {
            this.casasComunitarias = response.entity;
            this.totalDePaginas = response.quantity;

            this.resultadoCasasComunitarias = {
                casasComunitarias: this.casasComunitarias,
                totalDePaginas: this.totalDePaginas
            };

        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

}
