<div class="card card-gray-dark color-pallete-box">
  <div class="card-header">
    <h3 class="card-title">
      <i class="fas fa-child"></i>
      <a i18n> Unir Células </a>
    </h3>
    <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i
          class="fas fa-minus"></i></button></div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <label for="celulas-encerradas">Célula para realizar a união</label>
          <select class="form-control" name="celulas-encerradas" [(ngModel)]="dadosUniaoCelulaRequest.celulaOrigemId">
            <option [value]="null">Selecione uma célula</option>
            <option [value]="celula.id" *ngFor="let celula of celulasEncerradas">{{celula.nome}}
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <div class="form-group">
          <label for="celulas-encerradas">&nbsp;</label><br>
          <button class="btn btn-primary" (click)="unirCelulas()">Unir células</button>
        </div>
      </div>
    </div>
  </div>
</div>