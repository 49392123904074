<secured>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h3 i18n>Casa Comunitária</h3>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <form (ngSubmit)="buscarCasasComunitarias()" #casasComunitariasForm="ngForm" novalidate>
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Buscar Casas Comunitarias</h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="lcit_nome">Nome</label>
                        <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome"
                          placeholder="Digite o nome" i18n-placeholder>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_dtfund">Ano Fundação</label>
                        <input [(ngModel)]="busca.ano" name="ncc_dtfund" type="number" class="form-control"
                          id="ncc_dtfund" i18n-placeholder>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_r">Regional</label>
                        <select class="form-control" id="ncc_r" [(ngModel)]="busca.regionalId"
                          (change)="carregarMissoes()" name="ncc_r">
                          <option [value]="null" i18n>Selecione um Regional</option>
                          <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_missao">Missão</label>
                        <select class="form-control" id="ncc_missao" [(ngModel)]="busca.missaoId" name="ncc_missao">
                          <option [value]="null" i18n>Selecione uma Missão</option>
                          <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_missao">País</label>
                        <select class="form-control" id="ncc_missao" [(ngModel)]="busca.paisId"
                          (change)="carregarEstados()" name="ncc_missao">
                          <option [value]="null" i18n>Selecione um País</option>
                          <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_estado">Estado</label>
                        <select class="form-control" id="ncc_estado" [(ngModel)]="busca.estadoId"
                          (change)="carregarCidades()" name="ncc_estado">
                          <option [value]="null" i18n>Selecione um Estado</option>
                          <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <label i18n for="ncc_cidade">Cidade</label>
                        <select class="form-control" id="ncc_cidade" [(ngModel)]="busca.cidadeId" name="ncc_cidade">
                          <option [value]="null" i18n>Selecione uma Cidade</option>
                          <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                      <div class="form-group">
                        <label i18n for="lmis_f">Status</label>
                        <select class="form-control" id="lmis_f" [(ngModel)]="busca.statusId" name="lmis_f">
                          <option [value]="null" i18n>Selecione uma status</option>
                          <option *ngFor="let status of statusCasaComunitaria" [value]="status.id">{{status.nome}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                  <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;"
                    i18n>Limpar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <tabela-listagem-casa-comunitaria-comp [resultadoPesquisa]="resultadoCasasComunitarias"
              *ngIf="resultadoCasasComunitarias?.casasComunitarias != undefined"
              (clickPaginacaoEvento)="buscarCasasComunitarias($event)"></tabela-listagem-casa-comunitaria-comp>
          </div>
        </div>
      </div>
    </section>
  </div>
</secured>