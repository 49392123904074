import { Component, OnInit } from "@angular/core";
import { CasaComunitariaService } from "src/app/secured/wop/casacomunitaria/service/casacomunitaria.service";
import { ActivatedRoute } from "@angular/router";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { CasaComunitaria } from "src/app/model/casacomunitaria.model";

@Component({
	selector: "adicao-acompanhamento-casa-comunitaria-comp",
	templateUrl: "./adicao-acompanhamento-casa-comunitaria.component.html",
	styleUrls: ["./adicao-acompanhamento-casa-comunitaria.component.css"],
})
export class AdicaoAcompanhamentoCasaComunitariaComponent implements OnInit {
	public casaComunitariaId!: number;
	private casaComunitaria: CasaComunitaria;

	constructor(
		private readonly casaComunitariaService: CasaComunitariaService,
		private readonly route: ActivatedRoute,
		private readonly swtAlert2Service: SwtAlert2Service
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.casaComunitariaId = Number(params.get("id"));
		});
	}

	async carregarCasaComunitaria() {
		try {
			let response = await this.casaComunitariaService.find(
				this.casaComunitariaId
			);
		} catch (error) {
			this.swtAlert2Service.errorAlert(error.errors);
		}
	}
}
