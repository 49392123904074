import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CelulaService } from '../service/celula.service';
import { Celula } from 'src/app/model/celula.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type DadosUniaoCelulaRequest = {
  celulaDestinoId: number;
  celulaOrigemId: number;
};

@Component({
  selector: 'unir-celulas',
  templateUrl: './unircelulas.component.html',
  styleUrls: ['./unircelulas.component.css'],
})
export class UnircelulasComponent implements OnInit, OnDestroy {
  @Input()
  celula: Celula;
  public celulasEncerradas: Array<Celula>;
  public notifier: EventEmitter<boolean> = new EventEmitter();
  public dadosUniaoCelulaRequest: DadosUniaoCelulaRequest = {
    celulaDestinoId: null,
    celulaOrigemId: null,
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    public celulaService: CelulaService,
    public swtAlertService: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.obterCelulasEncerradas();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  obterCelulasEncerradas(): void {
    this.celulaService
      .obterCelulasEncerradas(this.celula.centroEvangelizacaoId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (celulas) => {
          this.celulasEncerradas = celulas;
          this.notifier.emit(true);
        },
        error: (err) => this.swtAlertService.errorAlert(err.errors),
      });
  }

  unirCelulas(): void {
    if (this.dadosUniaoCelulaRequest.celulaOrigemId == null) {
      this.swtAlertService.warningAlert(
        'Selecione a célula da qual os membros serão importados.'
      );
      return;
    }

    this.dadosUniaoCelulaRequest.celulaDestinoId = this.celula.id;

    this.celulaService
      .unirCelulas(this.dadosUniaoCelulaRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => this.swtAlertService.successAlert(response),
        error: (error) => this.swtAlertService.errorAlert(error.errors),
      });
  }
}
