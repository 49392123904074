import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class PesquisaCasaComunitariaComponent {
    constructor(regionalService, missaoService, paisService, estadoService, cidadeService, casaComunitariaService, elementoGrupoDominioService, swtAlert2Service) {
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.casaComunitariaService = casaComunitariaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.estados = new Array();
        this.paises = new Array();
        this.cidades = new Array();
        this.regionais = new Array();
        this.missoes = new Array();
        this.casasComunitarias = new Array();
        this.statusCasaComunitaria = new Array();
        this.busca = {
            nome: null,
            ano: null,
            regionalId: null,
            missaoId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            statusId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarPaises();
        this.carregarStatusCasaComunitaria();
        this.buscarCasasComunitarias();
    }
    carregarStatusCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'CASA_COMUNITARIA_STATUS'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.statusCasaComunitaria = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarRegionais() {
        this.regionalService.findAll().then((lista) => {
            this.regionais = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.busca.regionalId).then((response) => {
            this.missoes = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.busca.paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.busca.estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    buscarCasasComunitarias(parametrosBusca = undefined) {
        if (parametrosBusca) {
            this.busca.pageNumber = parametrosBusca.pageNumber;
            this.busca.quantityOfElements = parametrosBusca.quantityOfElements;
        }
        this.casaComunitariaService.buscarCasasComunitaria(this.busca).then((response) => {
            this.casasComunitarias = response.entity;
            this.totalDePaginas = response.quantity;
            this.resultadoCasasComunitarias = {
                casasComunitarias: this.casasComunitarias,
                totalDePaginas: this.totalDePaginas
            };
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
}
