import { Component, Input, OnInit } from "@angular/core";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { MissionarioMissaoAtualService } from "src/app/secured/wop/dadosvocacionais/service/missionariomissaoatual.service";
import { ImagemService } from "src/app/secured/wop/imagem/service/imagem.service";

@Component({
	selector: "visualizar-equipe-formacao-comp",
	templateUrl: "./visualizar-equipe-formacao.component.html",
	styleUrls: ["./visualizar-equipe-formacao.component.css"],
})
export class VisualizarEquipeFormacaoComponent implements OnInit {
	@Input()
	public casaComunitariaId: any;
	public listaEquipeFormacao: any;

	constructor(
		private readonly missionarioMissaoAtualService: MissionarioMissaoAtualService,
		private readonly imagemService: ImagemService,
		private readonly swtAlert2Service: SwtAlert2Service
	) {}

	ngOnInit() {
		(async () => {
			this.carregarEquipeFormacaoCasaComunitaria();
		})();
	}

	async carregarEquipeFormacaoCasaComunitaria(): Promise<any> {
		try {
			let response =
				await this.missionarioMissaoAtualService.findEquipeFormacaoPorCasaComunitaria(
					this.casaComunitariaId
				);
			this.listaEquipeFormacao = response.sort((a, b) =>
				a.pessoaNome.localeCompare(b.pessoaNome)
			);

			for (let i = 0; i < this.listaEquipeFormacao.length; i++) {
				if (this.listaEquipeFormacao[i].imagemId != null) {
					let blogImage = await this.getPessoaImagem(
						this.listaEquipeFormacao[i].imagemId
					);

					this.imagemService.createImage(
						blogImage,
						this.listaEquipeFormacao[i]
					);
				}
			}
		} catch (err) {
			this.swtAlert2Service.errorAlert(err.error.errors);
		}
	}

	async getPessoaImagem(id: number): Promise<any> {
		try {
			let response = await this.imagemService.getPessoaPhoto(id);
			return response.body;
		} catch (err) {
			this.swtAlert2Service.errorAlert(err.error.errors);
		}
	}
}
