import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DadosCadastroAcompanhamentoCasaComunitaria } from "../formulario-acompanhamento-casa-comunitaria/formulario-acompanhamento-casa-comunitaria.component";
import { ObterAcompanhamentoCasaComunitariaServiceService } from "./ObterAcompanhamentoCasaComunitariaService.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AcompanhamentoCasaComunitaria } from "src/app/model/acompanhamentocasacomunitaria.model";

@Component({
	selector: "app-visualizar-acompanhamento-casa-comunitaria",
	templateUrl: "./visualizar-acompanhamento-casa-comunitaria.component.html",
	styleUrls: ["./visualizar-acompanhamento-casa-comunitaria.component.css"],
})
export class VisualizarAcompanhamentoCasaComunitariaComponent
	implements OnInit, OnDestroy
{
	private $destroy = new Subject<void>();
	acompanhamentoCasaComunitariaId: number;
	public acompanhamentoCasaComunitaria: AcompanhamentoCasaComunitaria;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly obterAcompanhamentoService: ObterAcompanhamentoCasaComunitariaServiceService
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.acompanhamentoCasaComunitariaId = Number(params.get("id"));
			this.carregarDadosAcompanhamentoCasaComunitaria();
		});
	}

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	async carregarDadosAcompanhamentoCasaComunitaria() {
		this.obterAcompanhamentoService
			.executar(this.acompanhamentoCasaComunitariaId)
			.pipe(takeUntil(this.$destroy))
			.subscribe({
				next: (response) =>
					(this.acompanhamentoCasaComunitaria = response),
			});
	}

	converterPeriodoEmTexto(periodo: string) {
		switch (periodo) {
			case "MARCO_MAIO":
				return "De Março a Maio";
			case "JUNHO_AGOSTO":
				return "De Junho a Agosto";
			case "SETEMBRO_NOVEMBRO":
				return "De Setembro a Novembro";
			case "DEZEMBRO_FEVEREIRO":
				return "De Dezembro a Fevereiro";
			default:
				return "Não foi definido o período";
		}
	}
}
