import { Injectable } from '@angular/core';
import { Celula } from '../model/celula.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class CelulaBuilder extends AbstractBuilder<Celula> {
  reset(): void {
    this.entity = new Celula(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
