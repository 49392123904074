import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';

@Component({
  selector: 'encerrar-celula-rl-btn',
  templateUrl: './encerrar-celula.rl.component.html',
  styleUrls: ['./encerrar-celula.rl.component.css'],
})
export class EncerrarCelulaRLComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input()
  celula: Celula;
  @Output()
  public notifier: EventEmitter<boolean> = new EventEmitter();
  dataEncerramento: Date;

  constructor(
    public celulaService: CelulaService,
    public swtAlertService: SwtAlert2Service
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public encerrarCelula() {
    if (!this.dataEncerramento)
      this.swtAlertService.warningAlert(
        'Informe a data de encerramento da célula'
      );
    else
      this.celulaService
        .encerrarCelula(this.celula.id, this.dataEncerramento)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (str) => {
            this.swtAlertService.successAlert(str);
            this.notifier.emit(true);
          },
          error: (err) => this.swtAlertService.errorAlert(err),
        });
  }
}
