<ng-container>
  <button class="btn btn-sm btn-danger ml-1" data-toggle="modal" [attr.data-target]="'#encerrar-celula-' + celula?.id"
    title="Encerrar célula" alt="Encerrar célula">
    <i class="far fa-times-circle"></i>
  </button>
  <div class="modal fade" id="encerrar-celula-{{celula.id}}">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Encerrar Célula - {{celula.nome}}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="data-encerramento"></label>
            <input type="date" class="form-control" name="data-encerramento" [(ngModel)]="dataEncerramento">
          </div>
        </div>
        <div class="modal-footer justify-content-right">
          <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary ml-1" data-dismiss="modal" (click)="encerrarCelula()">Encerrar
            Célula</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>