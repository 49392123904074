import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { CasaComunitaria } from "src/app/model/casacomunitaria.model";
import { CasaComunitariaService } from "src/app/secured/wop/casacomunitaria/service/casacomunitaria.service";

@Component({
	selector: "exibir-detalhes-casa-comunitaria-comp",
	templateUrl: "./exibir-detalhes-casa-comunitaria.component.html",
	styleUrls: ["./exibir-detalhes-casa-comunitaria.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class ExibirDetalhesCasaComunitariaComponent implements OnInit {
	@Input()
	private casaComunitariaId!: number;
	public casaComunitaria: CasaComunitaria;

	constructor(
		private readonly casaComunitariaService: CasaComunitariaService,
		private readonly swtAlert2Service: SwtAlert2Service
	) {}

	ngOnInit() {
		this.carregarCasaComunitaria();
	}

	async carregarCasaComunitaria() {
		try {
			let response = await this.casaComunitariaService.find(
				this.casaComunitariaId
			);

			this.casaComunitaria = response.entity;
		} catch (error) {
			this.swtAlert2Service.errorAlert(error.errors);
		}
	}
}
