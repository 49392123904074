import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import 'select2';
import { environment } from "src/environments/environment";
export class MissionariosComponent {
    constructor(pessoaService, wopGetAllService, paisService, casaComunitariaService, swtAlert2Service, imagemService, route) {
        this.pessoaService = pessoaService;
        this.wopGetAllService = wopGetAllService;
        this.paisService = paisService;
        this.casaComunitariaService = casaComunitariaService;
        this.swtAlert2Service = swtAlert2Service;
        this.imagemService = imagemService;
        this.route = route;
        this.pessoas = new Array();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.casasComunitarias = new Array();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            difusaoAtualId: null,
            formaVidaId: null,
            estadoVidaId: null,
            casaComunitariaId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.tipoFiltro = String(this.route.snapshot.paramMap.get('tipo'));
        this.carregarPaises();
        this.carregarFormasVida();
        this.carregarEstadoVida();
        this.carregarMissoes();
        this.carregarDifusoes();
        this.buscarMissionarios();
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'FORMA_VIDA'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.formasVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'ESTADO_VIDA'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.estadoVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllMissao();
                this.missoes = response.entity;
                this.missoes = this.missoes.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDifusoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllDifusao();
                this.difusoes = response.entity;
                this.difusoes = this.difusoes.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(this.busca.missaoAtualId);
                this.casasComunitarias = response.entity;
                this.casasComunitarias = this.casasComunitarias.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    buscarMissionarios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscarPessoas(this.busca);
                this.pessoas = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.pessoas.length; i++) {
                    if (this.pessoas[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.pessoas[i].imagemId);
                        this.imagemService.createImage(blogImage, this.pessoas[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    deletarPessoa(id) {
        if (confirm('Deseja deletar esta pessoa?')) {
            this.pessoaService.delete(id)
                .then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarMissionarios();
            })
                .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
    setarRegistroDuplicado(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.setarRegistroDuplicado(pessoaId);
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarMissionarios();
    }
    getUrlImagem(imagemId) {
        return this.urlBaseImagem + imagemId;
    }
}
