import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../../pais/service/pais.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../../missao/service/missao.service';
import { RegionalService } from '../../regional/service/regional.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { EstadoService } from '../../estado/service/estado.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CentroEvangelizacaoService } from '../../centroevangelizacao/service/centroevangelizacao.service';
import { Celula } from 'src/app/model/celula.model';
import { CelulaService } from '.././service/celula.service';
import { CelulaBuilder } from 'src/app/builder/celula.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { LoggedInUserSharedDataService } from 'src/app/secured/layout/header/service/logged-in-user-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'celula-rl',
    templateUrl: './celula.rl.component.html'
})
export class CelulaRlComponent implements OnInit {

    public celula: Celula;
    public fases : Array<ElementoGrupoDominio>; 
    public missaoId : number;
    public centroEvangelizacaoId : number;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public celulaBuilder: CelulaBuilder;
    unsubscribe$ = new Subject<void>();

    constructor(
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public celulaService: CelulaService,
        public swtAlert2Service: SwtAlert2Service,
        private loggedInUserSharedDataService: LoggedInUserSharedDataService,
            
    ) 
    {
        this.celulaBuilder = new CelulaBuilder();
        this.celula = this.celulaBuilder.getInstance();
        this.fases = new Array<ElementoGrupoDominio>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
    }

    ngOnInit() { 
        this.carregarNiveisDaCelula();  
        this.loggedInUserSharedDataService.loggedInPerson$
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                next: (person) => {
                  this.missaoId =
                    person && person.vinculoMissao.missaoAtualId
                      ? person.vinculoMissao.missaoAtualId
                      : null;
                  if (this.missaoId)
                    this.carregarCev(this.missaoId);
                },
              });
    }

    carregarNiveisDaCelula() : void {

        let buscaFase = {
            grupoNome : 'FASE_CELULA'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
   

    carregarCev(missaoId : number): void {
       this.centroEvangelizacaoService.porMissao(missaoId).then((response: any) => {
            this.centrosEvangelizacao = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });;
    }

    salvar(celula: Celula) : void {

        celula.dataFundacao = new Date(celula.dataFundacao);
        this.celulaService.create(celula).then( (response:any) => {
            this.celula = this.celulaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}