import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Page } from 'src/app/model/page.model';
import {
  MissaoConfigService,
  MissaoConfiguracao,
} from '../../service/missao-config.service';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  QuestionarioConfigService,
  QuestionarioConfiguracao,
} from '../../service/questionario-config.service';

@Component({
  selector: 'app-listagem-periodo-liberacao-questionario-parecer',
  templateUrl:
    './listagem-periodo-liberacao-questionario-parecer.component.html',
  styleUrls: [
    './listagem-periodo-liberacao-questionario-parecer.component.css',
  ],
})
export class ListagemPeriodoLiberacaoQuestionarioParecerComponent
  implements OnInit, OnDestroy
{
  missaoConfiguracao?: MissaoConfiguracao = null;
  paginaPeriodoLiberacao?: Page<QuestionarioConfiguracao>;
  unsubscribe$ = new Subject<null>();

  constructor(
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private missaoConfigService: MissaoConfigService,
    private questionarioConfigService: QuestionarioConfigService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((missaoConfiguracao: MissaoConfiguracao) => {
        this.missaoConfiguracao = missaoConfiguracao;
        if (this.missaoConfiguracao) {
          this.carregarConfiguracaoQuestionario();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  carregarConfiguracaoQuestionario() {
    this.questionarioConfigService
      .obterPaginaPorMissaoId(this.missaoConfiguracao.missaoId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((page) => {
        this.paginaPeriodoLiberacao = page;
      });
  }

  atualizarPeriodo(periodo: QuestionarioConfiguracao) {
    this.questionarioConfigService
      .atualizarQuestionarioConfiguracao(periodo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.swtAlert.successAlert(
            'Configuração do questionário atualizada com sucesso!'
          );
          this.carregarConfiguracaoQuestionario();
        },
        (error) => {
          this.swtAlert.errorAlert(error.error.errors);
        }
      );
  }

  statusQuestionarioConfiguracao(nome: string): string {
    switch (nome) {
      case 'EM_DISCERNIMENTO_LOCAL': {
        return 'Em Discernimento - Local';
      }
      case 'EM_DISCERNIMENTO_REGIONAL': {
        return 'Em Discernimento - Regional';
      }
      case 'EM_DISCERNIMENTO_GOVERNO': {
        return 'Em Discernimento - Governo';
      }
      case 'CONCLUIDO': {
        return 'Concluído';
      }
      case 'DIVULGADO': {
        return 'Divulgado';
      }
    }
  }
}
