export class Celula {
  constructor(
    public id: number,
    public nome: string,
    public nivelCelulaId: number,
    public nivelCelulaNome: string,
    public dataFundacao: Date,
    public centroEvangelizacaoId: number,
    public centroEvangelizacaoNome: string,
    public regionalId: number,
    public missaoId: number,
    public dataEncerramento: boolean,
    public encerrada,
    public celulaUniaoId
  ) {}
}
