import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SecuredModule } from '../../layout/secured.module';
import { SearchCelulaResponsavelLocalRoutingModule } from './celula-dashboard-rl-routing.module';
import { SearchCelulaResponsavelLocalComponent } from './component/search-celula/search-celula.rl.component';
import { SearchCelulaAssistenteFormacaoModule } from '../assistente-formacao/assistente-formacao.module';
import { EncerrarCelulaRLComponent } from './component/encerrar-celula/encerrar-celula.rl.component';

@NgModule({
  imports: [
    CommonModule,
    SearchCelulaResponsavelLocalRoutingModule,
    SecuredModule,
    FormsModule,
    BrowserModule,
    SearchCelulaAssistenteFormacaoModule,
  ],
  declarations: [
    SearchCelulaResponsavelLocalComponent,
    EncerrarCelulaRLComponent,
  ],
  providers: [],
})
export class SearchCelulaRepsonsavelLocalModule {}
