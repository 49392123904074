import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, } from "@angular/core";
export class RowGerenciarMembroCelulaComponent {
    constructor(membroComunidadeService, elementoGrupoDominioService, swtAlert2Service) {
        this.membroComunidadeService = membroComunidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.carregarMembros = new EventEmitter();
        this.remocaoMembroComunidade = {
            motivoId: null,
        };
        console.log("Par", this.even);
    }
    ngOnInit() { }
    exibirSeMotivoSaidaForOutro(idMotivoSaida) {
        if (this.motivosSaida.length > 0) {
            for (let motivo of this.motivosSaida) {
                if (motivo.id == idMotivoSaida) {
                    if (motivo.valor == "O") {
                        this.exibirCampoDescricaoMotivoSaida = true;
                        break;
                    }
                    else {
                        this.exibirCampoDescricaoMotivoSaida = false;
                        break;
                    }
                }
            }
        }
        else {
            this.exibirCampoDescricaoMotivoSaida = false;
        }
    }
    informarSaidaDeMembroDaCelula(dados) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!dados.dataSaida)
                    throw Error("Informe a data de saída.");
                if (!dados.motivoId)
                    throw Error("Informe o motivo.");
                if (this.exibirCampoDescricaoMotivoSaida && !dados.descricaoMotivo)
                    throw Error("É necessário descrever o motivo da saída.");
                this.remocaoMembroComunidade = {
                    dataSaida: dados.dataSaida,
                    motivoId: dados.motivoId,
                    descricaoMotivo: dados.descricaoMotivo,
                    membroComunidadeId: dados.id,
                };
                const response = yield this.membroComunidadeService
                    .informarSaidaDeMembroDaCelula(this.remocaoMembroComunidade)
                    .toPromise();
                this.swtAlert2Service.successAlert(response);
                this.remocaoMembroComunidade = {
                    motivoId: null,
                };
                yield this.carregarMembrosCelula();
            }
            catch (e) {
                if (e.error)
                    this.swtAlert2Service.errorAlert(e.error);
                else
                    this.swtAlert2Service.errorAlert(e.message);
            }
        });
    }
    autoridadePodeRemoverMembro(membro) {
        switch (this.usuarioLogado.autorizacoesNome[0]) {
            case "ROLE_RESPONSAVEL_LOCAL": {
                const listaQuePodeSerRemovidaPorRL = ["P1", "P2", "D1", "D2"];
                return listaQuePodeSerRemovidaPorRL.includes(membro.nivelFormativoNome);
            }
            default: {
                return false;
            }
        }
    }
    carregarMembrosCelula() {
        this.carregarMembros.emit();
    }
    cancelarRemocaoDeMembroDaCelula() {
        this.membro.editando = false;
        this.membro.motivoId = null;
        this.membro.dataSaida = null;
        this.exibirCampoDescricaoMotivoSaida = false;
    }
}
