<tr [ngClass]="{'gray-row': even}">
  <td>{{membro.id}}</td>
  <td>
    <div class="text-center">
      <!--a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes"-->
      <img class="profile-user-img img-fluid img-circle"
        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="Imagem do membro">
      <!--/a-->
    </div>
  </td>
  <td><a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes">{{membro.pessoaNome}}</a>
  </td>
  <td>{{membro.dataEntrada | date : 'dd/MM/yyyy'}}</td>
  <td>
    <ng-container *ngIf="membro.editando == false || membro.editando == undefined">
      {{remocaoMembroComunidade.dataSaida != null ?
      (remocaoMembroComunidade.dataSaida | date :
      'dd/MM/yyyy') : 'Membro Atual'}}
    </ng-container>
    <ng-container *ngIf="membro.editando == true">
      <input [(ngModel)]="membro.dataSaida" name="nmembro_dtsaida" type="date" class="form-control"
        id="nmembro_dtsaida">
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="membro.editando == true; else naoAplicavel">
      <select class="form-control" id="lista_motivo_saida" [(ngModel)]="membro.motivoId"
        (change)="exibirSeMotivoSaidaForOutro(membro.motivoId)" name="lista_motivo_saida">
        <option [value]="null" i18n>
          Selecione o motivo</option>
        <ng-container *ngFor="let motivo of motivosSaida">

          <ng-container *ngIf="motivo.nome.toUpperCase() != 'DESLIGAMENTO' || 
                  (motivo.nome.toUpperCase() == 'DESLIGAMENTO' && autoridadePodeRemoverMembro(membro))">
            <option [value]="motivo.id">
              {{motivo.nome}}
            </option>
          </ng-container>

        </ng-container>

      </select>
    </ng-container>
    <ng-template #naoAplicavel>
      N/A
    </ng-template>
  </td>
  <td *ngIf="exibirCampoDescricaoMotivoSaida == true">
    <ng-container *ngIf="membro.motivoId">
      <input type="text" class="form-control" [(ngModel)]="membro.descricaoMotivo"
        placeholder="Descrição do motivo..." />
    </ng-container>
  </td>
  <td class="text-center">
    <button *ngIf="membro.editando == true" class="btn btn-sm btn-danger"
      (click)="informarSaidaDeMembroDaCelula(membro)" title="Remover membro" alt="Remover membro"><i
        class="fas fa-trash"></i></button>
    <button *ngIf="membro.editando == true" class="btn btn-sm btn-default ml-1"
      (click)="cancelarRemocaoDeMembroDaCelula()" title="Cancelar remoção" alt="Cancelar remoção"><i
        class="fas fa-times"></i></button>
    <button *ngIf="(membro.editando == false || membro.editando == undefined)" class="btn btn-sm btn-danger"
      (click)="membro.editando = true" title="Remover membro" alt="Remover membro"><i class="fas fa-trash"></i></button>
  </td>
</tr>