/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./row-listagem-casa-comunitaria.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./row-listagem-casa-comunitaria.component";
var styles_RowListagemCasaComunitariaComponent = [i0.styles];
var RenderType_RowListagemCasaComunitariaComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RowListagemCasaComunitariaComponent, data: {} });
export { RenderType_RowListagemCasaComunitariaComponent as RenderType_RowListagemCasaComunitariaComponent };
export function View_RowListagemCasaComunitariaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [["class", "text-center"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.casaComunitaria.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.casaComunitaria.nome; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.casaComunitaria.missaoNome; _ck(_v, 6, 0, currVal_2); }); }
export function View_RowListagemCasaComunitariaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "row-listagem-casa-comunitaria-comp", [], null, null, null, View_RowListagemCasaComunitariaComponent_0, RenderType_RowListagemCasaComunitariaComponent)), i1.ɵdid(1, 114688, null, 0, i2.RowListagemCasaComunitariaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RowListagemCasaComunitariaComponentNgFactory = i1.ɵccf("row-listagem-casa-comunitaria-comp", i2.RowListagemCasaComunitariaComponent, View_RowListagemCasaComunitariaComponent_Host_0, { casaComunitaria: "casaComunitaria" }, {}, ["*"]);
export { RowListagemCasaComunitariaComponentNgFactory as RowListagemCasaComunitariaComponentNgFactory };
