/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cadastro-conselho.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.component.ngfactory";
import * as i4 from "../../../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.component";
import * as i5 from "../../../../wop/pessoa/service/pessoa.service";
import * as i6 from "../../../../wop/pais/service/pais.service";
import * as i7 from "../../../../wop/missao/service/missao.service";
import * as i8 from "../../../cadastropublico/service/cadastro.publico.local.service";
import * as i9 from "../../../../../core/swtalert2/swtalert2.service";
import * as i10 from "../../../../wop/imagem/service/imagem.service";
import * as i11 from "./cadastro-conselho.component";
import * as i12 from "../../service/conselho.service";
import * as i13 from "../../service/missao-configuracao-shared-data.service";
var styles_CadastroConselhoComponent = [i0.styles];
var RenderType_CadastroConselhoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CadastroConselhoComponent, data: {} });
export { RenderType_CadastroConselhoComponent as RenderType_CadastroConselhoComponent };
function View_CadastroConselhoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [["for", "npastor_pessoa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pessoa"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "form-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["for", "button-add-pessoa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-target", "#listagem-pessoa-conselho"], ["data-toggle", "modal"], ["name", "button-add-pessoa"], ["style", "cursor: pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Selecionar o Membro do Conselho"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pessoaConselhoSelecionado == null) ? null : _co.pessoaConselhoSelecionado.nome); var currVal_1 = ((_co.pessoaConselhoSelecionado == null) ? null : _co.pessoaConselhoSelecionado.sobrenome); _ck(_v, 8, 0, currVal_0, currVal_1); }); }
function View_CadastroConselhoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Imagem do membro do conselho"], ["class", "profile-user-img img-fluid img-circle"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["alt", "Remover Membro do Conselho"], ["class", "btn btn-sm btn-danger ml-1"], ["title", "Remover Membro do Conselho"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removerConselho(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fas fa-trash-alt"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.thumbnail == null) ? "assets/images/ppl.png" : _v.context.$implicit.thumbnail); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.pessoaNome; _ck(_v, 6, 0, currVal_2); }); }
export function View_CadastroConselhoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "card card-gray-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cadastrar Membro do Conselho "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CadastroConselhoComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["name", "button-add-cord-col"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.adicionarConselho() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Adicionar Membro do Conselho"])), (_l()(), i1.ɵeld(11, 0, null, null, 13, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 12, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Foto"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CadastroConselhoComponent_2)), i1.ɵdid(24, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "listagem-pessoainfo-modal-component", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.selecionarConselho($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ListagemPessoaInfoModalComponent_0, i3.RenderType_ListagemPessoaInfoModalComponent)), i1.ɵdid(26, 114688, null, 0, i4.ListagemPessoaInfoModalComponent, [i5.PessoaService, i6.PaisService, i7.MissaoService, i8.CadastroPublicoLocalService, i9.SwtAlert2Service, i10.ImagemService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], buttonText: [2, "buttonText"], altButtonText: [3, "altButtonText"], onlyMissionaries: [4, "onlyMissionaries"], bringNotInformedWayOfLife: [5, "bringNotInformedWayOfLife"], filterByUserAccess: [6, "filterByUserAccess"], bringEntitiesWithoutLinkedUser: [7, "bringEntitiesWithoutLinkedUser"] }, { eventEmitter: "eventEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.conselho == null) ? null : _co.conselho.id); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.membrosConselho; _ck(_v, 24, 0, currVal_1); var currVal_2 = "listagem-pessoa-conselho"; var currVal_3 = "Selecione o Membro do Conselho"; var currVal_4 = "Selecionar"; var currVal_5 = "Selecionar Coordenador"; var currVal_6 = true; var currVal_7 = false; var currVal_8 = true; var currVal_9 = false; _ck(_v, 26, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_CadastroConselhoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cadastro-conselho", [], null, null, null, View_CadastroConselhoComponent_0, RenderType_CadastroConselhoComponent)), i1.ɵdid(1, 245760, null, 0, i11.CadastroConselhoComponent, [i12.ConselhoService, i13.MissaoConfiguracaoSharedDataService, i10.ImagemService, i9.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CadastroConselhoComponentNgFactory = i1.ɵccf("app-cadastro-conselho", i11.CadastroConselhoComponent, View_CadastroConselhoComponent_Host_0, {}, {}, []);
export { CadastroConselhoComponentNgFactory as CadastroConselhoComponentNgFactory };
