import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "visualizar-detalhes-casa-comunitaria-comp",
	templateUrl:
		"./visualizar-detalhes-completos-casa-comunitaria.component.html",
	styleUrls: [
		"./visualizar-detalhes-completos-casa-comunitaria.component.css",
	],
})
export class VisualizarDetalhesCompletosCasaComunitariaComponent
	implements OnInit
{
	casaComunitariaId: number;

	constructor(private readonly route: ActivatedRoute) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.casaComunitariaId = Number(params.get("id"));
		});
	}
}
