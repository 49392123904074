import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AdicionarAcompanhamentoCasaComunitariaService } from "./adicionar-acompanhamento-casa-comunitaria.service";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Router } from "@angular/router";

export interface DadosCadastroAcompanhamentoCasaComunitaria {
	ano: number;
	periodoAcompanhamento:
		| "MARCO_MAIO"
		| "JUNHO_AGOSTO"
		| "SETEMBRO_NOVEMBRO"
		| "DEZEMBRO_FEVEREIRO";
	casaComunitariaId: number;
	dataAcompanhamento: Date;
	partilhaFormador: string;
	orientacoesDadas: string;
}

@Component({
	selector: "formulario-acompanhamento-casa-comunitaria-comp",
	templateUrl: "./formulario-acompanhamento-casa-comunitaria.component.html",
	styleUrls: ["./formulario-acompanhamento-casa-comunitaria.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class FormularioAcompanhamentoCasaComunitariaComponent
	implements OnInit
{
	@Input()
	private casaComunitariaId: number;
	public dadosCadastroAcompanhamento: DadosCadastroAcompanhamentoCasaComunitaria =
		{} as DadosCadastroAcompanhamentoCasaComunitaria;
	public anoAtual: number = new Date().getFullYear();

	constructor(
		private readonly adicionarAcompanhamentoService: AdicionarAcompanhamentoCasaComunitariaService,
		private readonly swtAlertService: SwtAlert2Service,
		private readonly router: Router
	) {}

	ngOnInit() {}

	adicionarAcompanhamento(): void {
		this.dadosCadastroAcompanhamento.casaComunitariaId =
			this.casaComunitariaId;

		if (
			!this.dadosCadastroAcompanhamento.ano ||
			!this.dadosCadastroAcompanhamento.periodoAcompanhamento ||
			!this.dadosCadastroAcompanhamento.dataAcompanhamento
		)
			return;

		console.log("clicou em salvar");

		this.adicionarAcompanhamentoService
			.executar(this.dadosCadastroAcompanhamento)
			.subscribe({
				next: (resp) => {
					this.swtAlertService.successAlert(
						"Acompanhamento adicionado com sucesso!"
					);
					setTimeout(() => {
						this.router.navigate(["secured/acc/casascomunitarias"]);
					}, 5000);
				},
				error: (error) => this.swtAlertService.errorAlert(error.errors),
			});
	}

	validarAno(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		const valorDigitado = parseInt(inputElement.value, 10);

		if (!isNaN(valorDigitado) && valorDigitado < this.anoAtual - 1) {
			inputElement.value = (this.anoAtual - 1).toString();
			this.swtAlertService.warningAlert(
				"O ano que você digitou é inferior ao ano mínimo aceito, o valor foi atualizado para " +
					(this.anoAtual - 1).toString()
			);
		}
	}
}
