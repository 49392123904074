import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { GenericService } from "src/app/core/services/generic.service";
import { AcompanhamentoCasaComunitaria } from "src/app/model/acompanhamentocasacomunitaria.model";

@Injectable({
	providedIn: "root",
})
export class AtualizarAcompanhamentoCasaComunitariaService extends GenericService<AcompanhamentoCasaComunitaria> {
	constructor(protected http: HttpClient, protected router: Router) {
		super(http, router);
		this.setEntityType("acompanhamentocasacomunitaria");
	}

	executar(
		id: number,
		dados: AcompanhamentoCasaComunitaria
	): Observable<any> {
		return this.http.put<any>(
			`${this.apiURL}/${id}`,
			JSON.stringify(dados),
			{
				headers: this.getHeaders(),
			}
		);
	}
}
