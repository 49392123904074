import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { Page } from 'src/app/model/page.model';
import { environment } from 'src/environments/environment';

export type QuestionarioConfiguracao = {
  id: number;
  missaoId: number;
  questionarioId: number;
  anoFormativoId: number;
  periodoInicio: Date;
  periodoFim: Date;
  status: string;
};

@Injectable()
export class QuestionarioConfigService extends GenericService<QuestionarioConfiguracao> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('questionarioconfiguracao');
  }

  public obterPaginaPorMissaoId(missaoId: number) {
    return this.http.get<Page<QuestionarioConfiguracao>>(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioconfiguracao/pormissaoid/${missaoId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }

  public atualizarQuestionarioConfiguracao(
    dados: QuestionarioConfiguracao
  ): Observable<QuestionarioConfiguracao> {
    return this.http.put<QuestionarioConfiguracao>(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioconfiguracao/${dados.id}`,
      JSON.stringify(dados),
      {
        headers: this.getHeaders(),
      }
    );
  }

  public obterConfiguracaoQuestionarioPorMissaoEQuestionario(
    missaoId: number,
    questionarioId: number
  ) {
    return this.http.get<QuestionarioConfiguracao>(
      `${environment.moduloFormacao.urlModuloFormacao}missao/${missaoId}/questionario/${questionarioId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }
}
