<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Célula Comunitária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(celula)" #celulaForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-globe-americas"></i>
                            <a i18n> Dados da célula </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncelula_nome">Nome</label>
                                    <input [(ngModel)]="celula.nome" name="ncelula_nome" type="text" class="form-control" id="ncelula_nome" placeholder="Digite o nome da Célula" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncelula_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="celula.dataFundacao" name="ncelula_dtfund" type="date" class="form-control" id="ncelula_dtfund" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncelula_fase">Nivel da Célula</label>
                                    <select class="form-control" id="ncelula_fase" [(ngModel)]="celula.nivelCelulaId" name="ncelula_fase">
                                        <option [value]="null" i18n>Selecione um Nivel</option>
                                        <option *ngFor="let fase of fases" [value]="fase.id">{{fase.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncelula_cev">Centro de Evangelização</label>
                                    <select class="form-control" id="ncelula_cev" [(ngModel)]="celula.centroEvangelizacaoId" name="ncelula_cev">
                                        <option [value]="null" i18n>Selecione um centro de Evangelização</option>
                                        <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>