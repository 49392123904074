import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Select2Component } from 'src/app/core/select2/select2.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { TableComponent } from 'src/app/core/table/table.component';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { LeftSidebarAcessoNegadoModule } from './left-sidebar-acesso-negado/left-sidebar-acesso-negado.module';
import { LeftSidebarAcompMissionarioModule } from './left-sidebar-acompanhador-missionario/left-sidebar-acomp-missionario.module';
import { LeftSidebarAssistenteFormacaoModel } from './left-sidebar-assistente-formacao/left-sidebar-assistente-formacao.model';
import { LeftSidebarAssMissionarioModule } from './left-sidebar-assistente-missionario/left-sidebar-assistente-missionario.module';
import { LeftSidebarComissaoRemanejamentoModule } from './left-sidebar-comissao-remanejamento/left-sidebar-comissao-remanejamento.module';
import { LeftSidebarFormadorComunitarioModule } from './left-sidebar-formador-comunitario/left-sidebar-formador-comunitario.module';
import { LeftSidebarMissionarioModule } from './left-sidebar-missionario/left-sidebar-missionario.module';
import { LeftSidebarResponsavelLocalModule } from './left-sidebar-responsavel-local/left-sidebar-responsavel-local.module';
import { LeftSidebarModule } from './left-sidebar/left-sidebar.module';
import { RouterManagerService } from './routermanager.service';
import { SecuredRoutingModule } from './secured-routing.module';
import { SecuredComponent } from './secured.component';
import { LeftSidebarAssistenteGovernoModule } from './left-sidebar-assistente-governo/left-sidebar-assistente-governo.module';
import { LeftSidebarAcompCasaComunitariaModule } from './left-sidebar-acompanhador-casa-comunitaria/left-sidebar-acomp-casa-comunitaria.module';

@NgModule({
  declarations: [SecuredComponent, TableComponent, Select2Component],
  imports: [
    CommonModule,
    SecuredRoutingModule,
    HeaderModule,
    LeftSidebarModule,
    LeftSidebarMissionarioModule,
    LeftSidebarAcompMissionarioModule,
    LeftSidebarAcompCasaComunitariaModule,
    LeftSidebarAssMissionarioModule,
    LeftSidebarComissaoRemanejamentoModule,
    LeftSidebarAssistenteGovernoModule,
    LeftSidebarFormadorComunitarioModule,
    LeftSidebarResponsavelLocalModule,
    LeftSidebarAcessoNegadoModule,
    LeftSidebarAssistenteFormacaoModel,
    FooterModule,
  ],
  exports: [
    SecuredComponent,
    HeaderModule,
    LeftSidebarModule,
    LeftSidebarMissionarioModule,
    // LeftSidebarAcompMissionarioModule,
    LeftSidebarAssMissionarioModule,
    LeftSidebarComissaoRemanejamentoModule,
    LeftSidebarAssistenteGovernoModule,
    FooterModule,
    TableComponent,
    Select2Component,
  ],
  providers: [SwtAlert2Service, RouterManagerService],
})
export class SecuredModule {}
