<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n>Missionário(a) - {{pessoa.nome}}&nbsp;{{pessoa.sobrenome}}</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-pessoa-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill" href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab" aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="dados-vocacionais-tab" data-toggle="pill" href="#dadosvocacionais-tab-content" role="tab" aria-controls="dados-vocacionais-tab" aria-selected="false">Dados Vocacionais</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel" aria-labelledby="dados-pessoais-tab">
                            <div class="row">
                                <div *ngIf="(pessoa.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                    <div style="padding: 1rem 0rem 1rem 1rem;">
                                        <div class="card card-default">
                                            <div class="card-body">
                                                <div class="text-center" style="margin: 1rem auto 2rem;">
                                                    <div class="widget-user-image">
                                                        <img class="profile-user-img img-fluid img-circle elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="Imagem da pessoa" style="width: auto;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-8 col-md-8 col-lg-8">
                                    <div style="padding: 1rem;">
                                        <div class="card card-default">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_genero">Sexo: </label><br>
                                                            {{pessoa.genero}}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_dtnas">Data de Nascimento:</label><br>
                                                            {{pessoa.dataNascimento}}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_nacionalidade">Nacionalidade:</label>
                                                            <select class="form-control" id="npessoa_nacionalidade" [(ngModel)]="pessoa.nacionalidadeId" name="npessoa_nacionalidade" disabled>
                                                                <option disabled value="null" i18n>Não informada</option>
                                                                <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_estado_civil">Estado Civil:</label>
                                                            <select class="form-control" id="npessoa_estado_civil" [(ngModel)]="pessoa.estadoCivilId" name="npessoa_estado_civil" disabled>
                                                                <option disabled value="null" i18n>Selecione um estado civil</option>
                                                                <option *ngFor="let estadoCivil of estadosCivil" [value]="estadoCivil.id">{{estadoCivil.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_idiomas">Idiomas</label>
                                                            <select class="select2" name="linguas-select" multiple="multiple" data-placeholder="Nenhuma idioma informado" style="width: 100%;" disabled>
                                                                <option *ngFor="let lingua of linguas" [value]="lingua.id">{{lingua.nome}}</option>                                                                                   
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_habilidades">Habilidades</label>
                                                            <select class="select2" name="habilidades-select" multiple="multiple" data-placeholder="Nenhuma habilidade informada" style="width: 100%;" disabled>
                                                                <option *ngFor="let habilidade of habilidades" [value]="habilidade.id">{{habilidade.nome}}</option>                                                                                   
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_missao_origem">Missão de Origem:</label>
                                                            <select class="form-control" id="npessoa_missao_origem" [(ngModel)]="pessoa.vinculoMissao.missaoOrigemId" name="npessoa_missao_origem" disabled>
                                                                <option disabled value="null" i18n>Selecione uma missão</option>
                                                                <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_missao_atual">Missão Atual:</label>
                                                            <select class="form-control" id="npessoa_missao_origem" [(ngModel)]="pessoa.vinculoMissao.missaoAtualId" name="npessoa_missao_atual" disabled>
                                                                <option disabled value="null" i18n>Selecione uma missão</option>
                                                                <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_difusao_origem">Difusão de Origem</label>
                                                            <select class="form-control" id="npessoa_difusao_origem" [(ngModel)]="pessoa.vinculoDifusao.difusaoOrigemId" name="npessoa_difusao_origem" disabled>
                                                                <option value="null" i18n>Selecione uma difusão</option>
                                                                <option *ngFor="let difusao of difusoesVinculo" [value]="difusao.id">{{difusao.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_difusao_atual">Difusão Atual</label>
                                                            <select class="form-control" id="npessoa_difusao_atual" [(ngModel)]="pessoa.vinculoDifusao.difusaoAtualId" name="npessoa_difusao_atual" disabled>
                                                                <option value="null" i18n>Selecione uma difusão</option>
                                                                <option *ngFor="let difusao of difusoesVinculo" [value]="difusao.id">{{difusao.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- fim da row-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Escolaridade</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Nível de Escolaridade</th>
                                                            <th>Nome do Curso</th>
                                                            <th>Ano de Formação</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let escolaridade of escolaridades">
                                                            <td>{{escolaridade.nivelEscolaridadeNome}}</td>
                                                            <td>{{escolaridade.nomeGraduacao}}</td>
                                                            <td>{{escolaridade.anoFormacao}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Experiência Profissional</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Empresa</th>
                                                            <th>Cargo</th>
                                                            <th>Trabalho Iniciado Em</th>
                                                            <th>Trabalho Finalizado Em</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let trabalho of trabalhos">
                                                            <td>{{trabalho.empresa}}</td>
                                                            <td>{{trabalho.cargo}}</td>
                                                            <td>{{trabalho.iniciadoEm | date: 'dd/MM/yyyy'}}</td>
                                                            <td>{{trabalho.finalizadoEm | date: 'dd/MM/yyyy'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Vistos</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>País</th>
                                                            <th>Tipo de visto</th>
                                                            <th>Validade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let visto of vistos">
                                                            <td>{{visto.paisNome}}</td>
                                                            <td>{{visto.tipoVistoId != null ? visto.tipoVistoNome : visto.tipoVistoNaoListado}}</td>
                                                            <td>{{visto.dataValidade | date : 'dd/MM/yyyy'}}</td>
                                                        </tr>                                            
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Nacionalidades</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>Nacionalidade</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let pnac of pessoaNacionalidades">
                                                            <td>{{textoStatus(pnac)}}</td>
                                                            <td>{{textoNacionalidade(pnac)}}</td>
                                                        </tr>                                            
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->                                                        
                            <div></div><!-- fim da row-->
                            <div></div><!-- fim da row-->
                        </div>  
                        <div class="tab-pane fade" id="dadosvocacionais-tab-content" role="tabpanel" aria-labelledby="dadosvocacionais-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div style="padding: 1rem;">
                                        <div class="card card-default">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="formavida">Forma de Vida</label>
                                                            <select class="form-control" name="formavida" [(ngModel)]="formaVidaSelecionadaId" disabled>
                                                                <option *ngFor="let formaVida of formasVida"
                                                                    [value]="formaVida.id">
                                                                    {{formaVida.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="nivel_form">Nível Formação</label>
                                                            <select class="form-control" name="nivel_form" [(ngModel)]="dadoVocacional.nivelFormacaoId" disabled>
                                                                <option disabled value="null">Não informado</option>
                                                                <option *ngFor="let nivelFormacao of niveisFormacao"
                                                                    [value]="nivelFormacao.id">
                                                                    {{nivelFormacao.nome}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="ano_ingresso">Ano Ingresso (Postulantado)</label><br>
                                                            {{dadoVocacional.anoIngresso}}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="ano_prim_prom">Ano Primeiras Promessas</label><br>
                                                            {{dadoVocacional.anoPrimeiraPromessa}}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="ano_prom_def">Ano Promessas Definitivas</label><br>
                                                            {{dadoVocacional.anoPromessaDefinitiva}}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                                        <div class="form-group">
                                                            <label for="form_pessoal">Formador Pessoal</label>
                                                            <span class="form-control"
                                                                name="form_pessoal">{{pessoaFormadorPessoalSelecionado.nome}}
                                                                {{pessoaFormadorPessoalSelecionado.sobrenome}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label i18n for="npessoa_habilidades">Habilidades</label>
                                                            <select class="select2" name="habilidades-select" multiple="multiple" data-placeholder="Nenhuma habilidade informada" style="width: 100%;" disabled>
                                                                <option *ngFor="let habilidade of habilidades" [value]="habilidade.id">{{habilidade.nome}}</option>                                                                                   
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="estadovida">Estado de Vida</label>
                                                            <select class="form-control" name="estadovida" [(ngModel)]="estadoVidaSelecionadoId" disabled>
                                                                <option disabled [value]="null">Não Informado</option>
                                                                <option *ngFor="let estadoVida of estadosVida"
                                                                    [value]="estadoVida.id">{{estadoVida.nome}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="pessoa.estadoVidaValor == 'CVT' || pessoa.estadoVidaValor == 'CVP'">
                                                        <div class="card card-secondary">
                                                            <div class="card-header">
                                                                <h3 class="card-title">Informação sobre o Celibato</h3>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                                        <div class="form-group">
                                                                            <label for="dt-voto-temp">Data dos Votos Temporários</label><br>
                                                                            {{celibatario.dataVotosTemporarios}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-4 col-lg-4"
                                                                        *ngIf="pessoa.estadoVidaValor == 'CVP'">
                                                                        <div class="form-group">
                                                                            <label for="dt-voto-def">Data dos Votos Definitivos</label><br>
                                                                            {{celibatario.dataVotosDefinitivos}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT'">
                                                        <div class="card card-secondary">
                                                            <div class="card-header">
                                                                <h3 class="card-title">Informação sobre Relacionamento</h3>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <label for="relacionamentonome">Nome</label>
                                                                                    <span class="form-control"
                                                                                        name="relacionamentonome">{{pessoaRelacionamentoSelecionada.nome}}
                                                                                        {{pessoaRelacionamentoSelecionada.sobrenome}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 com-md-3 col-lg-3"
                                                                        *ngIf="pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT'">
                                                                        <div class="form-group">
                                                                            <label for="data_in_cam">Data de início da caminhada</label><br>
                                                                            {{relacionamento.dataInicioCaminhada}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 com-md-3 col-lg-3"
                                                                        *ngIf="pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT'">
                                                                        <div class="form-group">
                                                                            <label for="data_in_nam">Data de início do namoro</label><br>
                                                                            {{relacionamento.dataInicioNamoro}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 com-md-3 col-lg-3"
                                                                        *ngIf="pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT'">
                                                                        <div class="form-group">
                                                                            <label for="data_in_noi">Data de início do noivado</label><br>
                                                                            {{relacionamento.dataInicioNoivado}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 com-md-3 col-lg-3"
                                                                        *ngIf="pessoa.estadoVidaValor == 'MT'">
                                                                        <div class="form-group">
                                                                            <label for="data_mt">Data do Matrimônio</label><br>
                                                                            {{relacionamento.dataMatrimonio}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="pessoa.estadoVidaValor == 'SAC'">
                                                        <div class="card card-secondary">
                                                            <div class="card-header">
                                                                <h3 class="card-title">Informação sobre o Sacerdócio</h3>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                                                        <div class="form-group">
                                                                            <label for="lema_sac">Lema Sacerdotal</label><br>
                                                                            {{sacerdote.lemaSacerdotal}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                                        <div class="form-group">
                                                                            <label for="dt_ord">Data da Ordenação</label><br>
                                                                            {{sacerdote.dataOrdenacao}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <div class="checkbox icheck-concrete">
                                                                <input type="checkbox" id="hasFilho" name="hasFilho"
                                                                    [(ngModel)]="hasFilho" disabled/>&nbsp;
                                                                <label class="form-check-label" for="hasFilho">Tem
                                                                    filhos?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- fim da row-->
                            <div class="row" *ngIf="hasFilho">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informações sobre os Filhos</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Data de Nascimento</th>
                                                            <th>Cidade de Nascimento</th>
                                                            <th>Observações</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let filho of filhos">
                                                            <td>{{filho.filhoNome}}</td>
                                                            <td>{{filho.dataNascimento | date :
                                                                'dd/MM/yyyy'}}</td>
                                                            <td>{{filho.cidadeNascimento == null ?
                                                                filho.cidadeNaoCadastrada :
                                                                filho.cidadeNascimentoNome}}</td>
                                                            <td>{{filho.observacao}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Histórico Missões</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <table class="table table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>Regional</th>
                                                            <th>Missão</th>
                                                            <th>Casa Comunitária</th>
                                                            <th>Data de Chegada</th>
                                                            <th>Data de Saída</th>
                                                            <th>Apostolado</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let historico of historicosMissionarioMissao">
                                                            <td>{{historico.regionalNome}}</td>
                                                            <td>{{historico.missaoNome}}</td>
                                                            <td>{{historico.casaComunitariaNome}}</td>
                                                            <td>{{historico.dataChegada | date : 'dd/MM/yyyy'}}</td>
                                                            <td>{{historico.dataSaida != null ? (historico.dataSaida | date : 'dd/MM/yyyy') : 'Missão Atual'}}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-info" (click)="configModalHistoricoApostolado(historico.id)"
                                                                    data-toggle="modal"
                                                                    data-target="#modal-info-apostolado">
                                                                    Visualizar Apostolados
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div><!-- fim da row-->
                            <div></div><!-- fim da row-->
                            <div></div><!-- fim da row-->
                        </div>                      
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modal-info-apostolado" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Apostolados exercídos na missão</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">                                
                                <div class="card card-secondary">
                                    <div class="card-header">
                                        <h3 class="card-title">Informação sobre os apostolados exercidos na missão</h3>
                                    </div>
                                </div>
                            </div>
                       </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-default">
                                    <div class="card-body p-0">
                                        <table class="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Apostolado</th>
                                                    <th>Duração Anos</th>
                                                    <th>Duração Meses</th>
                                                    <th>Apostolado Atual</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let historicoApostolado of historicosMissionarioMissaoApostolados">
                                                    <td>{{historicoApostolado.apostoladoNome}}</td>
                                                    <td>{{historicoApostolado.duracaoAnos}}</td>
                                                    <td>{{historicoApostolado.duracaoMeses}}</td> 
                                                    <td>{{historicoApostolado.apostoladoAtual == true ? 'Sim' : 'Não'}}</td>                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-right">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</secured>