import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from "@angular/core";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { RemocaoMembroComunidade } from "../../remocaomembrocomunidade.request";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { MembroComunidadeService } from "../../../service/membro.comunidade.service";
import { ElementoGrupoDominioService } from "src/app/secured/wop/grupodominio/service/elementogrupodominio.service";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Usuario } from "src/app/model/usuario.model";

@Component({
	selector: "row-gerenciar-membro-celula-comp",
	templateUrl: "./row-gerenciar-membro-celula.component.html",
	styleUrls: ["./row-gerenciar-membro-celula.component.css"],
	encapsulation: ViewEncapsulation.None,
})
export class RowGerenciarMembroCelulaComponent implements OnInit {
	@Input()
	membro: MembroComunidade;
	@Input()
	even: boolean;
	@Input()
	motivosSaida: Array<ElementoGrupoDominio>;
	@Input()
	usuarioLogado: Usuario;
	@Output()
	carregarMembros = new EventEmitter<void>();
	public exibirCampoDescricaoMotivoSaida: boolean;
	public remocaoMembroComunidade = {
		motivoId: null,
	} as RemocaoMembroComunidade;

	constructor(
		private readonly membroComunidadeService: MembroComunidadeService,
		private readonly elementoGrupoDominioService: ElementoGrupoDominioService,
		private readonly swtAlert2Service: SwtAlert2Service
	) {
		console.log("Par", this.even);
	}

	ngOnInit() {}

	exibirSeMotivoSaidaForOutro(idMotivoSaida: number): void {
		if (this.motivosSaida.length > 0) {
			for (let motivo of this.motivosSaida) {
				if (motivo.id == idMotivoSaida) {
					if (motivo.valor == "O") {
						this.exibirCampoDescricaoMotivoSaida = true;
						break;
					} else {
						this.exibirCampoDescricaoMotivoSaida = false;
						break;
					}
				}
			}
		} else {
			this.exibirCampoDescricaoMotivoSaida = false;
		}
	}

	async informarSaidaDeMembroDaCelula(dados: MembroComunidade): Promise<any> {
		try {
			if (!dados.dataSaida) throw Error("Informe a data de saída.");

			if (!dados.motivoId) throw Error("Informe o motivo.");

			if (this.exibirCampoDescricaoMotivoSaida && !dados.descricaoMotivo)
				throw Error("É necessário descrever o motivo da saída.");

			this.remocaoMembroComunidade = {
				dataSaida: dados.dataSaida,
				motivoId: dados.motivoId,
				descricaoMotivo: dados.descricaoMotivo,
				membroComunidadeId: dados.id,
			} as RemocaoMembroComunidade;

			const response = await this.membroComunidadeService
				.informarSaidaDeMembroDaCelula(this.remocaoMembroComunidade)
				.toPromise();
			this.swtAlert2Service.successAlert(response);
			this.remocaoMembroComunidade = {
				motivoId: null,
			} as RemocaoMembroComunidade;
			await this.carregarMembrosCelula();
		} catch (e) {
			if (e.error) this.swtAlert2Service.errorAlert(e.error);
			else this.swtAlert2Service.errorAlert(e.message);
		}
	}

	autoridadePodeRemoverMembro(membro: MembroComunidade) {
		switch (this.usuarioLogado.autorizacoesNome[0]) {
			case "ROLE_RESPONSAVEL_LOCAL": {
				const listaQuePodeSerRemovidaPorRL = ["P1", "P2", "D1", "D2"];
				return listaQuePodeSerRemovidaPorRL.includes(
					membro.nivelFormativoNome
				);
			}
			default: {
				return false;
			}
		}
	}

	carregarMembrosCelula() {
		this.carregarMembros.emit();
	}

	cancelarRemocaoDeMembroDaCelula() {
		this.membro.editando = false;
		this.membro.motivoId = null;
		this.membro.dataSaida = null;
		this.exibirCampoDescricaoMotivoSaida = false;
	}
}
