import { GenericService } from 'src/app/core/services/generic.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class AdicionarAcompanhamentoCasaComunitariaService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType('acompanhamentocasacomunitaria');
    }
    executar(request) {
        return this.http.post(this.apiURL, JSON.stringify(request), { 'headers': this.getHeaders() });
    }
}
AdicionarAcompanhamentoCasaComunitariaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdicionarAcompanhamentoCasaComunitariaService_Factory() { return new AdicionarAcompanhamentoCasaComunitariaService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: AdicionarAcompanhamentoCasaComunitariaService, providedIn: "root" });
