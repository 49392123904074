import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Celula } from 'src/app/model/celula.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Usuario } from 'src/app/model/usuario.model';
import { QuestionarioFormandoService } from '../../questionario/service/questionarioformando.service';
import { ParecerResponsavelService } from '../service/parecerresponsavel.service';

import { CaminhoFormativo } from 'src/app/model/caminhoformativo.model';
import { CaminhoFormativoService } from '../service/caminhoformativo.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MissaoConfigService } from '../../missao-config-comp/service/missao-config.service';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { CelulaService } from 'src/app/secured/wop/celula/service/celula.service';
import { MembroComunidadeService } from 'src/app/secured/wop/celula/service/membro.comunidade.service';
import { CoordenadorColegiadoService } from '../../colegiado/coordenadorcolegiado.service';
import { FormadorComunitarioService } from 'src/app/secured/wop/celula/service/formador.comunitario.service';
import { FormadorAssistenteService } from 'src/app/secured/wop/celula/service/formador.assist.service';
import { DiscernimentoService } from '../../discernimentos/service/discernimento.service';
import { ArquivoService } from '../../questionario/service/arquivo.service';
import {
  QuestionarioConfigService,
  QuestionarioConfiguracao,
} from '../../missao-config-comp/service/questionario-config.service';

@Component({
  selector: 'app-parecer-cc',
  templateUrl: './parecer.cc.celula.component.html',
  styleUrls: [],
})
export class ParecerCoordenadorColegiadoCelulaComponent implements OnInit {
  unsubscribe$ = new Subject<null>();

  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public questionariosColegiado: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public caminhoFormativo: CaminhoFormativo;

  public formadoresDaMissao: any;

  public paginacaoConfig: any;
  public questionarioFormandoSelectedId: number;
  public questionarioSelectedId: number;
  public pedidoFormandoId: number;

  questionarioConfiguracao: QuestionarioConfiguracao;

  selectedFile: File | null = null;
  errorMessage: string = '';
  isFormValidUpload: boolean = false;

  public sanitizedPdfUrl: SafeResourceUrl;
  public sanitizedPdfCartaUrl: SafeResourceUrl;
  @ViewChild('pdfViewer', { static: false })
  pdfViewer: ElementRef;

  constructor(
    private missaoConfiguracaoService: MissaoConfigService,
    private questionarioConfigService: QuestionarioConfigService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public coordenadorColegiadoService: CoordenadorColegiadoService,
    public formadorComunitarioService: FormadorComunitarioService,
    public formadorAssistenteService: FormadorAssistenteService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public caminhoFormativoService: CaminhoFormativoService,
    public discernimentoService: DiscernimentoService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
    this.paginacaoConfig = {
      page: 0,
      size: 10,
      totalPages: 0,
      totalElements: 0,
    };
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadoresCelula();
  }

  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.questionarioConfigService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarFormadoresCelula(): Promise<any> {
    try {
      let response =
        await this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelLocal(
          this.paginacaoConfig.page,
          this.paginacaoConfig.size
        );
      this.formadoresDaMissao = response.content;
      this.paginacaoConfig.totalPages = response.totalPages;
      this.paginacaoConfig.totalElements = response.totalElements;

      const idMembros = this.formadoresDaMissao.map((membro) => membro.id);

      await this.carregarQuestionarioColegiado(idMembros);

      if (this.formadoresDaMissao.length > 0) {
        this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.formadoresDaMissao.length; i++) {
        if (this.formadoresDaMissao[i].imagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.formadoresDaMissao[i].imagemId
          );
          this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
        }

        const questionario = this.questionariosColegiado.filter(
          (questionarioFormando) =>
            questionarioFormando.id === this.formadoresDaMissao[i].id
        );

        if (questionario.length > 0) {
          this.formadoresDaMissao[i].statusQuestionario =
            questionario[0].status_questionario;
          this.formadoresDaMissao[i].idQuestionarioFormando =
            questionario[0].questionarioFormandoId;
          this.formadoresDaMissao[i].questionarioId =
            questionario[0].questionarioId;
          this.formadoresDaMissao[i].statusParecerFC =
            questionario[0].status_parecer_fc;
          this.formadoresDaMissao[i].idParecerFC = questionario[0].parecerIdFC;
          this.formadoresDaMissao[i].acompanhadoPor =
            questionario[0].acompanhadoPor;
          this.formadoresDaMissao[i].acompanhadoPorNome =
            questionario[0].acompanhadoPorNome;
          this.formadoresDaMissao[i].arquivo = questionario[0].arquivo;
          this.formadoresDaMissao[i].dataEnvioParecerFC =
            questionario[0].dataEnvioParecerFC;
          this.formadoresDaMissao[i].dataEnvioQuestionario =
            questionario[0].dataEnvioQuestionario;
          this.formadoresDaMissao[i].missaoAtualId =
            questionario[0].pessoamissaoAtualId;
          this.formadoresDaMissao[i].carta = questionario[0].carta;

          if (this.formadoresDaMissao[i].statusQuestionario == 'NAOINICIADO') {
            this.formadoresDaMissao[i].statusQuestionario = 'Não Iniciado';
          }
          if (this.formadoresDaMissao[i].statusParecerFC == 'NAOINICIADO') {
            this.formadoresDaMissao[i].statusParecerFC = 'Não Iniciado';
          }
        } else {
          this.formadoresDaMissao[i].statusQuestionario = null;
          this.formadoresDaMissao[i].idQuestionarioFormando = null;
          this.formadoresDaMissao[i].questionarioId = null;
          this.formadoresDaMissao[i].statusParecerFC = null;
          this.formadoresDaMissao[i].idParecerFC = null;
          this.formadoresDaMissao[i].arquivo = null;
          this.formadoresDaMissao[i].dataEnvioParecerFC = null;
          this.formadoresDaMissao[i].dataEnvioQuestionario = null;
        }

        /*if (!this.questionarioFormando || !this.questionarioFormando.id) {
          this.questionarioFormando = await this.carregarQuestionario(
            this.formadoresDaMissao[i].pessoaId
          );

          if (this.questionarioFormando) {
            if (!this.questionarioConfiguracao) {
              this.carregarQuestionarioConfiguracao(
                this.questionarioFormando.questionarioId
              );
            }
          }
        } else if (!this.questionarioConfiguracao) {
          this.carregarQuestionarioConfiguracao(
            this.questionarioFormando.questionarioId
          );
        }*/
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioColegiado(idMembros: any): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorMembrosId(
        idMembros
      );
      this.questionariosColegiado = response;
    } catch (e) {
      console.log(e);
    }
  }

  async consultarCaminhoFormativo(formando: any): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        formando.pessoaId
      );
      if (response != null && response != undefined) {
        this.caminhoFormativo = response;
      } else {
        this.caminhoFormativo = {} as CaminhoFormativo;
        this.caminhoFormativo.pessoaId = formando.pessoaId;
        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
        this.caminhoFormativo.ano_formativo_id = 2;
        this.caminhoFormativo.nome_formador_comunitario =
          this.pessoa.nome + ' ' + this.pessoa.sobrenome;
        let response = await this.caminhoFormativoService.cadastrar(
          this.caminhoFormativo
        );

        this.caminhoFormativo = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      await this.consultarCaminhoFormativo(formando);
      let responsePorPessoa =
        await this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(
          formando.pessoaId,
          formando.questionarioId
        );
      if (
        responsePorPessoa != undefined &&
        responsePorPessoa != null &&
        responsePorPessoa.questionarioId == formando.questionarioId
      ) {
        this.questionarioFormando = responsePorPessoa;
        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
        //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
      } else {
        this.questionarioFormando.anoFormativoId = 2;
        this.questionarioFormando.questionarioId = formando.questionarioId;
        this.questionarioFormando.pessoaId = formando.pessoaId;
        let response = await this.questionarioFormandoService.cadastrar(
          this.questionarioFormando
        );
        if (response != undefined && response != null) {
          this.questionarioFormando = response;
          this.questionarioFormandoSelectedId = this.questionarioFormando.id;
          formando.idQuestionarioFormando = this.questionarioFormando.id;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoParecerCAL(
    questionarioFormandoId: number
  ): Promise<any> {
    try {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 3;
      this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
      this.parecerResponsavel.tipo = 'FC';
      let response = await this.parecerResponsavelService.cadastrar(
        this.parecerResponsavel
      );
      if (response != undefined && response != null) {
        this.parecerResponsavel = response;
        this.router.navigate([
          '/secured/parecer-cal-answer-default' +
            '/' +
            this.parecerResponsavel.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async consultarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      if (
        formando.idQuestionarioFormando != undefined &&
        formando.idQuestionarioFormando != null
      ) {
        if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
          this.router.navigate([
            '/secured/parecer-cal-answer-default' + '/' + formando.idParecerFC,
          ]);
        } else {
          this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
        }
      } else if (
        formando.idQuestionarioFormando == undefined ||
        formando.idQuestionarioFormando == null
      ) {
        await this.cadastrarVinculoQuestionarioCAL(formando);
        await this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async configModalUploadQuestionario(formando: any): Promise<any> {
    if (
      formando.questionarioFormandoId != undefined &&
      formando.questionarioFormandoId != null
    ) {
      this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
      this.questionarioSelectedId = formando.questionarioId;
    } else {
      await this.cadastrarVinculoQuestionarioCAL(formando);
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
    if (
      file &&
      file.type === 'application/pdf' &&
      file.size <= 3 * 1024 * 1024
    ) {
      this.selectedFile = file;
      this.errorMessage = '';
    } else {
      this.selectedFile = null;
      this.errorMessage = 'O arquivo deve ser um PDF com no máximo 3 MB.';
    }
    this.checkFormUploadValidity();
  }

  onUpload() {
    if (this.selectedFile && this.pedidoFormandoId) {
      this.questionarioFormandoService
        .uploadQuestionario(
          this.questionarioFormandoSelectedId,
          this.pedidoFormandoId,
          this.selectedFile
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            this.swtAlert2Service.successAlert(data);
            this.carregarFormadoresCelula();
            $('#modal-upload-questionario').modal('hide');
          },
          error: (error) => {
            this.swtAlert2Service.errorAlert(
              'Erro ao realizar o upload do arquivo.'
            );
          },
          complete: () => {
            this.unsubscribe$.next();
          },
        });
      this.pedidoFormandoId = null;
    } else {
      if (!this.pedidoFormandoId) {
        this.errorMessage =
          "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
      } else {
        this.errorMessage =
          'Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb';
      }
    }
  }

  onTipoPedidoChange() {
    this.checkFormUploadValidity();
  }

  // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
  checkFormUploadValidity() {
    this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
  }

  /**
   * CARTA
   */

  async configModalUploadCarta(formando: any): Promise<any> {
    if (
      formando.questionarioFormandoId != undefined &&
      formando.questionarioFormandoId != null
    ) {
      this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
    } else {
      await this.cadastrarVinculoQuestionarioCAL(formando);
    }
  }

  async carregarCartaPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfCartaUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onCartaFileSelected(event: any) {
    const file: File = event.target.files[0];
    // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
    if (
      file &&
      file.type === 'application/pdf' &&
      file.size <= 3 * 1024 * 1024
    ) {
      this.selectedFile = file;
    } else {
      this.selectedFile = null;
    }
  }

  onCartaUpload() {
    if (this.selectedFile) {
      this.questionarioFormandoService
        .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFile)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => {
            this.swtAlert2Service.successAlert(data);
            this.carregarFormadoresCelula();
            $('#modal-upload-carta').modal('hide');
          },
          error: (error) => {
            this.swtAlert2Service.errorAlert(
              'Erro ao realizar o upload do arquivo.'
            );
          },
          complete: () => {
            this.unsubscribe$.next();
          },
        });
    }
  }

  cliqueNoBotaoListener(botao) {
    this.paginacaoConfig.page = botao.numero - 1;
    this.carregarFormadoresCelula();
  }
}
