import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'select2';
import { environment } from "src/environments/environment";
export class MissionariosBuscaAvancadaComponent {
    constructor(router, route, wopGetAllService, swtAlert2Service, missionarioService, pessoaService, imagemService, apostoladoService, missionarioBuilder, casaComunitariaService) {
        this.router = router;
        this.route = route;
        this.wopGetAllService = wopGetAllService;
        this.swtAlert2Service = swtAlert2Service;
        this.missionarioService = missionarioService;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.apostoladoService = apostoladoService;
        this.missionarioBuilder = missionarioBuilder;
        this.casaComunitariaService = casaComunitariaService;
        this.paises = new Array();
        this.missoes = new Array();
        this.missionarios = new Array();
        this.habilidades = new Array();
        this.linguas = new Array();
        this.niveisEscolaridade = new Array();
        this.status = new Array();
        this.casasComunitarias = new Array();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            faixaEtaria: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            difusaoAtualId: null,
            formaVidaId: null,
            estadoVidaId: null,
            habilidadesIds: null,
            idiomasIds: null,
            nivelEscolaridadeId: null,
            status: 124,
            apostoladosAtuaisIds: null,
            apostoladosJaExercidosIds: null,
            pessoasIds: null,
            casaComunitariaId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
        this.pessoasFazendoTerapia = 0;
        this.pessoasCV = 0;
        this.pessoasCA = 0;
        this.totalMissionarios = 0;
    }
    ngOnInit() {
        this.tipoFiltro = String(this.route.snapshot.paramMap.get('tipo'));
        this.carregarFormasVida();
        this.carregarNiveisFormacao();
        this.carregarEstadoVida();
        this.carregarPaises();
        this.carregarMissoes();
        this.carregarDifusoes();
        this.carregarLinguas();
        this.carregarHabilidades();
        this.carregarNiveisEscolaridade();
        this.carregarStatus();
        this.carregarApostolados();
        this.buscarMissionarios();
        $("select[name='linguas-select']").select2({
            theme: "bootstrap4"
        });
        $("select[name='habilidades-select']").select2({
            theme: "bootstrap4"
        });
        $("select[name='apostoladoatual-select']").select2({
            theme: "bootstrap4"
        });
        $("select[name='apostoladojaexercido-select']").select2({
            theme: "bootstrap4"
        });
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'FORMA_VIDA'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.formasVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarNiveisFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'NIVEL_FORMACAO'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.niveisFormacao = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'ESTADO_VIDA'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.estadoVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllPais();
                this.paises = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllMissao();
                this.missoes = response.entity;
                this.missoes = this.missoes.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDifusoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllService.getAllDifusao();
                this.difusoes = response.entity;
                this.difusoes = this.difusoes.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(this.busca.missaoAtualId);
                this.casasComunitarias = response.entity;
                this.casasComunitarias = this.casasComunitarias.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarHabilidades() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'HABILIDADE'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.habilidades = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarLinguas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'LINGUA'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.linguas = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarNiveisEscolaridade() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'NIVEL_ESCOLARIDADE'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.niveisEscolaridade = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'PESSOA_STATUS'
            };
            try {
                let response = yield this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
                this.status = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    buscarMissionarios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.busca.idiomasIds = $("select[name='linguas-select']").val();
                this.busca.habilidadesIds = $("select[name='habilidades-select']").val();
                this.busca.apostoladosAtuaisIds = $("select[name='apostoladoatual-select']").val();
                this.busca.apostoladosJaExercidosIds = $("select[name='apostoladojaexercido-select']").val();
                let response = yield this.missionarioService.buscarMissionarios(this.busca);
                this.pessoasFazendoTerapia = response.fazTerapia;
                this.pessoasCV = response.comunidadeVida;
                this.pessoasCA = response.comunidadeAlianca;
                this.totalMissionarios = response.totalMissionarios;
                this.missionarios = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.missionarios.length; i++) {
                    if (this.missionarios[i].imagemId != null) {
                        let blogImage = yield this.getMissionarioImagem(this.missionarios[i].imagemId);
                        this.imagemService.createImage(blogImage, this.missionarios[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getMissionarioImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                ativo: null
            };
            try {
                let response = yield this.apostoladoService.pesquisar(request);
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    setarRegistroDuplicado(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.setarRegistroDuplicado(pessoaId);
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarMissionarios();
    }
}
