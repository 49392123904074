/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabela-listagem-casa-comunitaria.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./row-listagem-casa-comunitaria/row-listagem-casa-comunitaria.component.ngfactory";
import * as i3 from "./row-listagem-casa-comunitaria/row-listagem-casa-comunitaria.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../../core/table/table.component.ngfactory";
import * as i6 from "../../../../../core/table/table.component";
import * as i7 from "@angular/common";
import * as i8 from "./tabela-listagem-casa-comunitaria.component";
var styles_TabelaListagemCasaComunitariaComponent = [i0.styles];
var RenderType_TabelaListagemCasaComunitariaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabelaListagemCasaComunitariaComponent, data: {} });
export { RenderType_TabelaListagemCasaComunitariaComponent as RenderType_TabelaListagemCasaComunitariaComponent };
function View_TabelaListagemCasaComunitariaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "row-listagem-casa-comunitaria-comp", [], null, null, null, i2.View_RowListagemCasaComunitariaComponent_0, i2.RenderType_RowListagemCasaComunitariaComponent)), i1.ɵdid(2, 114688, null, 0, i3.RowListagemCasaComunitariaComponent, [], { casaComunitaria: [0, "casaComunitaria"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "button", [["alt", "Adicionar Acompanhamento"], ["class", "btn btn-sm btn-default ml-1"], ["title", "Adicionar Acompanhamento"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "button", [["alt", "Visualizar Detalhes da Casa"], ["class", "btn btn-sm btn-secondary ml-1"], ["title", "Visualizar Detalhes da Casa"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-eye"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "../adicaoacompanhamento/", _v.context.$implicit.id, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "../visualizardetalhescompletos/", _v.context.$implicit.id, ""); _ck(_v, 7, 0, currVal_2); }, null); }
function View_TabelaListagemCasaComunitariaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "table-component", [], null, [[null, "eventoBotao"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventoBotao" === en)) {
        var pd_0 = (_co.cliqueNoBotaoListener($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_TableComponent_0, i5.RenderType_TableComponent)), i1.ɵdid(1, 638976, null, 0, i6.TableComponent, [], { cols: [0, "cols"], rows: [1, "rows"], totalDePaginas: [2, "totalDePaginas"], tituloSessao: [3, "tituloSessao"] }, { eventoBotao: "eventoBotao" }), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "tr", [["tableheader", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["style", "width: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Miss\u00E3o"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_TabelaListagemCasaComunitariaComponent_2)), i1.ɵdid(11, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 4; var currVal_1 = 10; var currVal_2 = ((_co.resultadoPesquisa == null) ? null : _co.resultadoPesquisa.totalDePaginas); var currVal_3 = "Casas Comunitarias"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ((_co.resultadoPesquisa == null) ? null : _co.resultadoPesquisa.casasComunitarias); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_TabelaListagemCasaComunitariaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabelaListagemCasaComunitariaComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultadoPesquisa.casasComunitarias; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabelaListagemCasaComunitariaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tabela-listagem-casa-comunitaria-comp", [], null, null, null, View_TabelaListagemCasaComunitariaComponent_0, RenderType_TabelaListagemCasaComunitariaComponent)), i1.ɵdid(1, 114688, null, 0, i8.TabelaListagemCasaComunitariaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabelaListagemCasaComunitariaComponentNgFactory = i1.ɵccf("tabela-listagem-casa-comunitaria-comp", i8.TabelaListagemCasaComunitariaComponent, View_TabelaListagemCasaComunitariaComponent_Host_0, { resultadoPesquisa: "resultadoPesquisa" }, { clickPaginacaoEvento: "clickPaginacaoEvento" }, []);
export { TabelaListagemCasaComunitariaComponentNgFactory as TabelaListagemCasaComunitariaComponentNgFactory };
