import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
export class ListagemMembrosCasaComunitariaComponent {
    constructor(missionarioMissaoAtualService, pessoaService, imagemService, swtAlert2Service) {
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.swtAlert2Service = swtAlert2Service;
        this.membrosCasaComunitaria = new Array();
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements: 5,
        };
    }
    ngOnInit() {
        (() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarMembrosCasaComunitaria();
        }))();
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.busca.casaComunitariaId = this.casaComunitariaId;
                // this.busca.formaVidaId = 17; // Comunidade de Vida
                let response = yield this.pessoaService.buscarMembrosCasaComunitaria(this.busca);
                this.membrosCasaComunitaria = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    if (this.membrosCasaComunitaria[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
