<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n>Missionários</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarMissionarios();" #permissoesForm="ngForm" novalidate>
                            <div class="card card-gray-dark color-pallete-box collapsed-card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Missionarios</h3>
                                    <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-plus"></i></button></div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lpessoa_nome" type="text" class="form-control" id="lpessoa_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_sobrenome">Sobrenome</label>
                                                <input [(ngModel)]="busca.sobrenome" name="lpessoa_sobrenome" type="text" class="form-control" id="lpessoa_sobrenome" placeholder="Digite o sobrenome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_genero">Sexo</label>
                                                <select [(ngModel)]="busca.genero" class="form-control" id="lpessoa_genero" name="lpessoa_genero">
                                                    <option value="null" i18n>Selecione o sexo</option>
                                                    <option value="masculino" i18n>Masculino</option>
                                                    <option value="feminino" i18n>Feminino</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_idade">Idade</label>
                                                <select [(ngModel)]="busca.faixaEtaria" class="form-control" id="lpessoa_idade" name="lpessoa_idade">
                                                    <option [value]="null">Escolha a faixa etária</option>
                                                        <option [value]="'18 - 25'">18 - 25</option>
                                                        <option [value]="'25 - 30'">25 - 30</option>
                                                        <option [value]="'30 - 35'">30 - 35</option>
                                                        <option [value]="'35 - 40'">35 - 40</option>
                                                        <option [value]="'40 - 45'">40 - 45</option>
                                                        <option [value]="'45+'">45+</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_nacionalidade">Nacionalidade</label>
                                                <select [(ngModel)]="busca.nacionalidadeId" class="form-control" id="lpessoa_nacionalidade" name="lpessoa_nacionalidade">
                                                    <option [value]="null" i18n>Selecione a Nacionalidade</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_missao">Missão</label>
                                                <select [(ngModel)]="busca.missaoAtualId" class="form-control" id="ngo_missao" name="ngo_missao" (change)="carregarCasasComunitarias()">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="difusoes">Difusão</label>
                                                <select class="form-control" id="difusoes" name="ngo_missao" [(ngModel)]="busca.difusaoAtualId">
                                                    <option [value]="null" i18n>Selecione uma Difusão</option>
                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="casa_comunitaria">Casa Comunitária</label>
                                                <select class="form-control" id="casa_comunitaria" name="ngo_missao" [(ngModel)]="busca.casaComunitariaId">
                                                    <option [value]="null" i18n>Selecione uma Casa Comunitária</option>
                                                    <option *ngFor="let casaComunitaria of casasComunitarias" [value]="casaComunitaria.id">{{casaComunitaria.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Forma de Vida</label>
                                                <select [(ngModel)]="busca.formaVidaId" class="form-control" id="npessoa_formavida" name="npessoa_formavida">
                                                    <option value="null" i18n>Selecione a sua forma de vida</option>
                                                    <option *ngFor="let formaVida of formasVida" [value]="formaVida.id">{{formaVida.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Estado de Vida</label>
                                                <select [(ngModel)]="busca.estadoVidaId" class="form-control" id="npessoa_estadovida" name="npessoa_estadovida">
                                                    <option value="null" i18n>Selecione o seu estado de vida</option>
                                                    <option *ngFor="let estadoVida of estadoVida" [value]="estadoVida.id">{{estadoVida.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Habilidades</label>
                                                <select class="select2" name="habilidades-select" multiple="multiple" data-placeholder="Selecione a(s) habilidade(s)" style="width: 100%;">
                                                    <option *ngFor="let habilidade of habilidades" [value]="habilidade.id">{{habilidade.nome}}</option>                                                                                   
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Idiomas</label>
                                                <select class="select2" name="linguas-select" multiple="multiple" data-placeholder="Selecione a(s) língua(s)" style="width: 100%;">
                                                    <option *ngFor="let lingua of linguas" [value]="lingua.id">{{lingua.nome}}</option>                                                                                   
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="npessoa_nivelescolaridade">Nível de Escolaridade</label>
                                                <select [(ngModel)]="busca.nivelEscolaridadeId" name="npessoa_nivelescolaridade" id="npessoa_nivelescolaridade" class="form-control">
                                                    <option [value]="null">Selecione um nível de escolaridade</option>
                                                    <option *ngFor="let nivel of niveisEscolaridade" [value]="nivel.id">{{nivel.nome}}</option>                                                                                        
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="(tipoFiltro == 'det')" class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="pessoa_status">Status</label>
                                                <select [(ngModel)]="busca.status" name="pessoa_status" id="pessoa_status" class="form-control">
                                                    <option [value]="null">Status</option>
                                                    <option [value]="124" >Ativo</option>
                                                    <option [value]="125" >Inativo</option>
                                                    <!-- <option *ngFor="let st of status" [value]="st.id">{{st.nome}}</option>  -->
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="busca_apostoladoatual">Apostolado atual</label>
                                                <select class="select2" name="apostoladoatual-select" multiple="multiple" data-placeholder="Selecione o(s) apostolado(s)" style="width: 100%;">
                                                    <option *ngFor="let apostolado of apostolados" [value]="apostolado.id">{{apostolado.nome}}</option>                                                                                   
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="busca_apostoladojaexercidos">Apostolado já exercido</label>
                                                <select class="select2" name="apostoladojaexercido-select" multiple="multiple" data-placeholder="Selecione o(s) apostolado(s)" style="width: 100%;">
                                                    <option *ngFor="let apostolado of apostolados"
                                                        [value]="apostolado.id">{{apostolado.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>                                                                                
                                    </div>
                                    
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{pessoasFazendoTerapia}}</h3>
                                        <p>Pessoas fazendo terapia</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3>{{pessoasCV}}</h3>
                                        <p>Comunidade de vida</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3>{{pessoasCA}}</h3>
                                        <p>Comunidade de aliança</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3>{{totalMissionarios}}</h3>
                                        <p>Total Missionários</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style='text-align: center'>
                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Lista dos Missionários'" [cols]="12" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th>Foto de perfil</th>
                                <th>Nome</th>
                                <th>Sobrenome</th>
                                <th>Missão</th>                                
                                <th>Difusão</th>                                                             
                                <th>Sexo</th>
                                <th>Idade</th>
                                <th>Nacionalidade</th>
                                <th>Estado de Vida</th>
                                <th>Idiomas</th>
                                <th *ngIf="(tipoFiltro == 'det')">Registro Duplicado?</th>
                                <th></th>
                            </tr>
                            
                            <tr tablebody *ngFor="let missionario of missionarios">
                                <td>
                                    <div class="widget-user-image">
                                        <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="missionario.thumbnail == null ? 'assets/images/ppl.png' : missionario.thumbnail" alt="User Avatar">
                                    </div>
                                </td>
                                <td>{{missionario.nome}}</td>
                                <td>{{missionario.sobrenome}}</td>
                                <td>{{missionario.missaoNome}}</td>
                                <td>{{missionario.difusaoNome}}</td>
                                <td>{{missionario.sexo}}</td>
                                <td>{{missionario.idade}}</td>
                                <td>{{missionario.nacionalidadeNome}}</td>
                                <td>{{missionario.estadoVida}}</td>
                                <td>{{missionario.idiomas}}</td>
                                <td *ngIf="(tipoFiltro == 'det')"><input type="checkbox" [(ngModel)]="missionario.registroDuplicado" (change)="setarRegistroDuplicado(missionario.pessoaId)" name="registro_duplicado" id="registro_duplicado"></td>
                                <td class="text-center">
                                    <button *ngIf="(tipoFiltro == 'det')" class="btn btn-sm btn-primary" routerLink="../../../missionario_detalhado/{{missionario.pessoaId}}" title="Visualizar os dados do missionário" alt="Visualizar os dados do missionário"><i class="fas fa-eye"></i></button>&nbsp;
                                    <button *ngIf="(tipoFiltro != 'det')" class="btn btn-sm btn-primary" routerLink="../../../missionario/{{missionario.pessoaId}}" title="Visualizar os dados do missionário" alt="Visualizar os dados do missionário"><i class="fas fa-eye"></i></button>&nbsp;
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>