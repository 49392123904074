<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3>Acompanhamento - Casa Comunitária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <exibir-detalhes-casa-comunitaria-comp
                            [casaComunitariaId]="casaComunitariaId"></exibir-detalhes-casa-comunitaria-comp>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <formulario-acompanhamento-casa-comunitaria-comp
                            [casaComunitariaId]="casaComunitariaId"></formulario-acompanhamento-casa-comunitaria-comp>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>