import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { AcompanhamentoCasaComunitaria } from 'src/app/model/acompanhamentocasacomunitaria.model';

@Injectable({
  providedIn: 'root'
})
export class AdicionarAcompanhamentoCasaComunitariaService extends GenericService<AcompanhamentoCasaComunitaria> {

    constructor(protected http: HttpClient, protected router:Router) {
            super(http,router);
            this.setEntityType('acompanhamentocasacomunitaria');
    }

    executar(request: any) : Observable<AcompanhamentoCasaComunitaria> {
        return this.http.post<any>(this.apiURL, JSON.stringify(request), { 'headers' : this.getHeaders() });
    }

}
